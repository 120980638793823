import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { DAO, RegistrationModal } from 'src/app/modal/DAO';
import { ServiceLayer } from 'src/app/services/serviceLayer';
import { CommonService } from 'src/app/services/commonService';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-user-creation',
  templateUrl: './user-creation.component.html'
})
export class UserCreationComponent implements OnInit {

  @Input() userData: RegistrationModal;
  @Input() isEditUser: boolean = false;
  @Input() imsData: any;
  @Output() closeButtonClicked = new EventEmitter();
  
  constructor(public dao: DAO, private messageService: MessageService,
    public serviceLayer: ServiceLayer,public appComponent: AppComponent,
    private commonService: CommonService, public appConfig: AppConfig) { }

  ngOnInit(): void {
  }

   // save user data
   public saveUserData(form) : void{
    if(this.isEditUser){
      this.validateEmailAndUpdateUserData();
    }else{
      this.postRegistration(form);
    }
  }

  // Register new user data
  public postRegistration(form) : void{
    this.serviceLayer.postRegistration();
    if(form !=null){
      form.submitted = false
    }
  }

  
  // validate mail ID
  public validateEmail() : boolean{
    return this.serviceLayer.validateEmails(this.userData.email);
  }

  // reset RegistrationModal
  public resetRegistrationModel() : void{
    this.userData = new RegistrationModal();
    if (!!this.dao.IsInternalUser && !!this.dao.UserManagement.userSearch) {
        this.dao.RegistrationModal.agentCode = this.dao.UserManagement.userSearch.agencyCode;
    }
  }

   // Agency Dropdown - auto complete
  public async getAgencyList(event){
    if (!!event.query && event.query.length > 2) {
      await this.serviceLayer.GetAgencyList(event.query);
    }
  }

  // close Edit user Propup
  public closeEditUserPopup() : void{
    this.closeButtonClicked.emit();
  } 

  // Validate Email and Update user data
  public validateEmailAndUpdateUserData():void{
    if(this.imsData != null && this.userData != null && !!this.imsData.email && !!this.userData.email){
      if(this.imsData.email.trim() != this.userData.email.trim()){
        this.checkEmailIDAndUpdateUserData();
      }else{
        this.updateUserData();
      }
    }
  }

  // Check whether the entered email already exists in IMS or not. And then update user data to IMS.
  public checkEmailIDAndUpdateUserData():void{
    let payload = { "urlData": [this.dao.userCompany, this.userData.email] };
    this.commonService.get('Login', 'CheckUser', payload).subscribe((event: any) => {
      if (!!event && !!!event.isSuccess) {
        this.updateUserData(); // Update user data to IMS
      }else if (!!event && !!event.isSuccess) {
        this.appComponent.registrationMsgPopup(event?.responseText);
      }
    });
  }

  // Update user data to IMS
  public updateUserData() : void{
    if (this.dao.ClaimEmptyModel?.length > 0 && this.userData != null && this.imsData !=null) {
      this.dao.ClaimEmptyModel[0].id = this.imsData.claimRoleId;
      this.dao.ClaimEmptyModel[0].claimValue = this.userData.securityRole;

      let id = this.imsData.securityIdentifier;
      if (this.appConfig.auth0Enabled) {
        id = this.imsData.id;
      }
      let urlData = [this.dao.userCompany, id, this.userData.givenName, this.userData.surName,this.userData.email];
      let payload = { "urlData": urlData, "payloadData": this.dao.ClaimEmptyModel }

      this.commonService.put('Agent', 'UpdateUserProfile', payload).subscribe(updatedUser =>
        {
          if(updatedUser != null && this.dao.UserManagement.UsersList != null){
            var userIndex = this.dao.UserManagement.UsersList.findIndex(x=>x.securityIdentifier  == this.imsData.securityIdentifier);
            if (userIndex >= 0)
            {
              var userList = this.dao.UserManagement.UsersList;
              // updatedUser does not have a correct created date as per company because IMS Provide Info without company details. So that we are storing created date from userList and change the model again;
              let createdDate = userList[userIndex].createdDate;
              // updating the userList with Latest result
              userList[userIndex] = updatedUser;
              userList[userIndex].createdDate = createdDate;
              this.dao.UserManagement.UsersList =  [...userList];
            }
            this.openToast('success',"User has been updated successfully");
            this.closeEditUserPopup();
          }
          else{
            this.openToast('error',"Error Occured");
          }   
      });
    }
  }

  // ========= Open popup =====================
  public openToast(severity:string, msg: string = '') {
    this.messageService.add({ key: 'myKey2', severity: severity, summary: 'Agent Registration', detail: msg != '' ? msg : this.dao.toastValidation });
  }

}
