import { Injectable, Injector } from '@angular/core';
import { CommonService } from '../services/commonService';
import { FileuploadService } from '../services/fileupload.service';
import { DAO, emptyModal, ProductDetails, ProductModel, RegistrationModal, vehicleDetails, VehicleModel } from '../modal/DAO';
import { HttpResults, MenuClickContext, workInProgress } from '../modal/routing.models';
import { ChangeDetectionService } from '../services/changeDetectionService';
import { BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import _ from 'lodash';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import * as $ from "jquery";
import { NgForm } from '@angular/forms';
import { ResponseContainer, QuestionAnswer, Questionnaire } from '../modal/questionnaire.models';
import { Observable } from 'rxjs-compat';
import { PolicyHolderValueParser } from '../modal/PolicyHolderValueParser.models';
import { DateService } from './date.service';
declare var require: any;
declare var jQuery: any;

@Injectable()
export class ServiceLayer {

  public dotSubmissionDetails = new BehaviorSubject<ResponseContainer>(null);

  public get dotSubmissionDetails$(): Observable<ResponseContainer> {
    return this.dotSubmissionDetails.asObservable();
  }

  public menuClickBehavior = new BehaviorSubject<MenuClickContext>(null);

  public saveDatas = [{ 'order': 1, 'template': 'AccountInfo', 'component': 'policy-holder', 'functionality': 'savePolicyHolder' },
  { 'order': 2, 'template': 'uw', 'component': 'under-writer', 'functionality': 'saveUnderwriter' },
  { 'order': 3, 'template': 'losshistory', 'component': 'loss-history', 'functionality': 'saveLossHistory' },
  { 'order': 4, 'template': 'vehicles', 'component': 'vehicle', 'functionality': 'saveVehicle' },
  { 'order': 5, 'template': 'driver', 'component': 'driver', 'functionality': 'saveDriver' },
  { 'order': 6, 'template': 'product', 'component': 'product', 'functionality': 'saveProduct' },
  { 'order': 7, 'template': 'application', 'component': 'application', 'functionality': 'saveAppInfo' },
  ]

  constructor(private commonService: CommonService,
    public dao: DAO,
    public appComponent: AppComponent,
    private messageService: MessageService,
    public datepipe: DatePipe,
    public inject: Injector,
    public dataChangeService: ChangeDetectionService,
    public fileuploadService: FileuploadService,
    public dateService: DateService,
  ) { }
  // ========= Get DOT # =====================
  public async getDOTNumber(isDOTExists: boolean = false) {
    let payload = { "urlData": [this.dao.userCompany] }
    var clientData: any = await this.commonService.get('policy-holder', 'getDOTNumber', payload).toPromise();
    this.dao.DOTList = ResponseContainer.fromJSON(clientData);
    return this.dao.DOTList;
  }

  // ========= Get DOT service =====================
  public async getDOTDetailsService(payload) {
    return await this.commonService.post('policy-holder', 'getDOTDetails', payload)
      .pipe(
        map((detail: ResponseContainer | any) => {
          if (detail != null && detail.isStatusReceived) {
            this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
            detail = null;
          }
          else {
            detail = ResponseContainer.fromJSON(detail);
            this.dao.DOTList = detail;
            this.dao.finalSubmitPage = detail.finalSubmitPage;
          }
          this.dotSubmissionDetails.next(detail);
          return detail;
        })
      )
      .toPromise();
  }


  // ========= Get DOT details =====================
  public async getDOTDetails(IsDOTExists: boolean = false, page: string = 'AccountInfo', IsDeclined: boolean = false) {
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, IsDOTExists, page, 'dot', this.dao.IsWIPCall, false], "payloadData": this.dao.DOTList }
    if (this.dao.DOTList != null && this.dao.DOTList.questionnaire != undefined) {
      return await this.getDOTDetailsService(payload).then(async (data: any) => {
        if (!data) {
          return;
        }
        this.dao.navigatePage = this.dao.finalSubmitPage;

        if (this.dao.DOTList != null) {
          // Check if CAB is unavailable and prompt the user with a proper message
          if (!!this.dao.DOTList.cabHttpStatusCode && this.dao.DOTList.cabHttpStatusCode == "500") {
            this.dao.DOTLookupStatusMsg = [];
            this.dao.DOTLookupStatusMsg.push('Portal is experiencing intermittent system outage, please try after some time');
            return;
          }
          if (!IsDeclined) {
            await this.checkEvaluatequestionaire('LookUpDot');
          }
          this.setInitialData();
          if (page == "product") {
            this.dao.InitialAddAnotherInsuredForCargo = _.cloneDeep(this.dao.AddAnotherInsuredForCargo);
          }
          return data;
        }
      });

    }
  }
  // ========= Check the questionaire =====================
  public async checkEvaluatequestionaire(page, isWIP: boolean = false) {
    //====================get DOT look up decline messages=======================
    this.dao.DOTLookupStatusMsg = [];
    let jsonPathRef = require('jsonpath');
    let IsCancelledDOT = false;
    let IsDeclinedDOT = false;
    if (this.dao.DOTList != null) {
      this.dao["Is" + page + "Entered"] = true;

      let notifyData = jsonPathRef.query(this.dao.DOTList, '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusCode');
      if (notifyData && notifyData.length > 0 && notifyData.filter(c => c == 'Decline').length > 0) {
        IsDeclinedDOT = true;
      }
      if (notifyData && notifyData.length > 0 && notifyData.filter(c => c == 'Cancel').length > 0) {
        IsCancelledDOT = true;
      }

      let searchQueryForDeclineObj = '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusDesc';
      let declientArrayList = jsonPathRef.query(this.dao.DOTList, searchQueryForDeclineObj);

      let declineObjPath;
      if (declientArrayList && declientArrayList.length > 0) {
        declineObjPath = declientArrayList.filter(C => C.includes('EvaluateSubmission')).length > 0 ? declientArrayList.filter(C => C.includes('EvaluateSubmission'))[0] : declientArrayList[0];
      }
      // declientArrayList.forEach(declineObjPath => {
      if (declineObjPath != null) {
        let declinePathArray = declineObjPath.split(".");
        if (declinePathArray != null && declinePathArray.length > 1) {
          let declineMsgQuery = "$..[?(@.key=='" + declinePathArray[0] + "')].questionAnswer[?(@.key=='" + declinePathArray[1] + "')].answers[?(@.datasource=='Agent')].answer"
          let declineMsgArray = jsonPathRef.query(this.dao.DOTList, declineMsgQuery);
          if (declinePathArray[1] == 'Cab Not Dot Found') {
            declineMsgArray = [];
            declineMsgArray.push(this.dao.DOTList.questionnaire[0].questionAnswer[0].answers.filter(c => c.datasource == 'Agent')[0].answer);
          }
          if (declineMsgArray != null && declineMsgArray.length > 0) {
            this.dao.IsPolicyDateNotified = false;
            if (this.dao.DOTList.questionnaire?.filter(c => c.key == 'EvaluatePolicyHolder')[0]?.questionAnswer?.filter(s => s.key == 'PolicyEffectiveDate' && s.status == 'Notify')?.length > 0) {
              this.dao.IsPolicyDateNotified = true;
            }
            this.dao.DOTLookupStatusMsg.push(declineMsgArray[0]);
            this.dao["Is" + page + "Entered"] = false;
          }
        }
      }
      // });
    }
    //====set template name============
    this.dao.isDOTValid = true;
    if (this.dao.DOTLookupStatusMsg.length == 0 && !isWIP) {
      if (page === 'AccountInfo') {
        this.dao.PolicyTemplate = 'AccountInfo';
      } else if (page === 'uw') {
        this.dao.PolicyTemplate = 'uw';
      } else if (page === 'LossHistory') {
        this.dao.PolicyTemplate = 'losshistory';
      }
      else if (page === 'vehicles') {
        this.dao.PolicyTemplate = 'vehicles';
      }
      else if (page === 'driver') {
        this.dao.PolicyTemplate = 'driver';
      }
      else if (page === 'product') {
        this.dao.PolicyTemplate = 'product';
      }
      else if (page === 'application') {
        this.dao.PolicyTemplate = 'application';
      }
    }
    else if (this.dao.DOTLookupStatusMsg.length > 0 && isWIP) {
      if (page === 'AccountInfo' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'AccountInfo';
      } else if (page === 'UW' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'uw';
      } else if (page === 'LossHistory' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'losshistory';
      }
      else if (page === 'vehicles' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'vehicles';
      }
      else if (page === 'driver' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'driver';
      }
      else if (page === 'product' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'product';
      }
      else if (page === 'application' && !this.dao.IsPolicyDateNotified) {
        this.dao.PolicyTemplate = 'application';
      }
      if (IsCancelledDOT) {
        await this.CancelmySubmission();
      }

      if (!this.dao.IsPolicyDateNotified) {
        document.body.style.pointerEvents = "none";
        let getpopUpVerbiage = 'The effective date must be within 05-60 calendar days from today’s date. Please update "Policy Effective Date" field on Policy Holder screen in order to proceed with the submission';
        if (this.dao.PolicyTemplate == 'AccountInfo')
          getpopUpVerbiage = this.dao.DOTLookupStatusMsg[0];
        this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: getpopUpVerbiage });
        return true;
      }
    }
    else if (this.dao.DOTLookupStatusMsg.length > 0 && !isWIP && (IsDeclinedDOT || IsCancelledDOT)) {
      document.body.style.pointerEvents = "none";
      let msg = this.dao.DOTLookupStatusMsg;
      this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: msg[0], summary: IsCancelledDOT ? "cancelSubmission" : "declineSaved" });
      this.dao.DOTLookupStatusMsg = [];
      this.dao.isDOTValid = false;
      return true;
    }
    if (page == 'LookUpDot') {
      //code moved to policy holder component
    }
    window.scrollTo(0, 300);
    return true;
  }
  // ========== Commmon validation =============
  public async commonValidation() {

    return true;
  }
  // ======== before save =============
  public async setQuestionnairesData() {
    //set policy holder data has moved to policy holder component
    if (this.dao.PolicyTemplate == 'vehicles') {
      await this.setUnitData();
    }
    if (this.dao.PolicyTemplate == 'product') {
      await this.setProductData();
    }
  }

  // ========== Set vehicle json ===============
  public async setUnitData() {
    let unitJSON = [];
    const uniqueAddInsured: { type: string, name: string }[] = [];
    if (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length > 0) {
      this.dao.vehicleModel.VehiclesList.forEach((vehicle, i) => {
        if (vehicle.zoneList && vehicle.zoneList.length > 0) {
          let tempZone = _.cloneDeep(vehicle.vehicleToZone);
          let tempZonePercent = _.cloneDeep(vehicle.vehicleApplicableZonePercent);
          vehicle.zoneList.forEach((z, j) => {
            if (j == 0) {
              vehicle.vehicleToZone.answer = z.zone;
              vehicle.vehicleApplicableZonePercent.answer = z.percentage;
            }
            else {
              let maxindex = j + 1;
              tempZone.answer = z.zone;
              tempZone.rowIndex = maxindex;
              tempZonePercent.answer = z.percentage;
              tempZonePercent.rowIndex = maxindex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(unitJSON);
              tempArray.push(tempZone)
              tempArray.push(tempZonePercent);
              unitJSON = _.cloneDeep(tempArray);
            }
          });
        }
        if (vehicle.interestList && vehicle.interestList.length > 0) {
          let tempVehicle = _.cloneDeep(vehicle);
          let tempaddInsuredInterestType = tempVehicle.addInsuredInterestType;
          let tempaddInsuredName = tempVehicle.addInsuredName;
          let tempaddInsuredStreetAddress = tempVehicle.addInsuredStreetAddress;
          let tempaddInsuredCity = tempVehicle.addInsuredCity;
          let tempaddInsuredState = tempVehicle.addInsuredState;
          let tempaddInsuredPostalCode = tempVehicle.addInsuredPostalCode;
          let tempaddInsuredLoanNumber = tempVehicle.addInsuredLoanNumber;
          vehicle.interestList.forEach((z, j) => {
            let rIndex = 1 + uniqueAddInsured.findIndex((obj) => { return obj.type == z.Type && obj.name == z.Name });
            //each additional insured provider has its own unique row index
            if (rIndex == 0) {
              uniqueAddInsured.push({ type: z.Type, name: z.Name });
              rIndex = 1 + uniqueAddInsured.findIndex((obj) => { return obj.type == z.Type && obj.name == z.Name });
            }
            if (j == 0) {
              vehicle.addInsuredInterestType.answer = z.Type;
              vehicle.addInsuredInterestType.rowIndex = rIndex;
              vehicle.addInsuredName.answer = z.Name;
              vehicle.addInsuredName.rowIndex = rIndex;
              vehicle.addInsuredStreetAddress.answer = z.streetAddress;
              vehicle.addInsuredStreetAddress.rowIndex = rIndex;
              vehicle.addInsuredCity.answer = z.city;
              vehicle.addInsuredCity.rowIndex = rIndex;
              vehicle.addInsuredState.answer = z.state;
              vehicle.addInsuredState.rowIndex = rIndex;
              vehicle.addInsuredPostalCode.answer = z.zip;
              vehicle.addInsuredPostalCode.rowIndex = rIndex;
              vehicle.addInsuredLoanNumber.answer = z.loanNo;
              vehicle.addInsuredLoanNumber.rowIndex = rIndex;
            }
            else {
              tempaddInsuredInterestType.answer = z.Type;
              tempaddInsuredInterestType.rowIndex = rIndex;
              tempaddInsuredName.answer = z.Name;
              tempaddInsuredName.rowIndex = rIndex;
              tempaddInsuredStreetAddress.answer = z.streetAddress;
              tempaddInsuredStreetAddress.rowIndex = rIndex;
              tempaddInsuredCity.answer = z.city;
              tempaddInsuredCity.rowIndex = rIndex;
              tempaddInsuredState.answer = z.state;
              tempaddInsuredState.rowIndex = rIndex;
              tempaddInsuredPostalCode.answer = z.zip;
              tempaddInsuredPostalCode.rowIndex = rIndex;
              tempaddInsuredLoanNumber.answer = z.loanNo;
              tempaddInsuredLoanNumber.rowIndex = rIndex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(unitJSON);
              tempArray.push(tempaddInsuredInterestType);
              tempArray.push(tempaddInsuredName);
              tempArray.push(tempaddInsuredStreetAddress);
              tempArray.push(tempaddInsuredCity);
              tempArray.push(tempaddInsuredState);
              tempArray.push(tempaddInsuredPostalCode);
              tempArray.push(tempaddInsuredLoanNumber);
              unitJSON = _.cloneDeep(tempArray);
            }
          });
        }
        Object.keys(vehicle).forEach(key => {
          if (key != 'group' && key != 'zoneList' && key != 'interestList' && key != 'isReviewed' && typeof (vehicle[key]) == 'object') {
            if (key != 'vehicleToZone' && key != 'vehicleApplicableZonePercent' && key != 'addInsuredInterestType' && key != 'addInsuredName' && key != 'addInsuredStreetAddress' && key != 'addInsuredCity' && key != 'addInsuredState' && key != 'addInsuredPostalCode' && key != 'addInsuredLoanNumber') {
              vehicle[key].rowIndex = i + 1;
            }
            unitJSON.push(vehicle[key]);
          }
          // setting the isreviewed and save to db for showing and hiding Save and Continue button in the screen 
          if (key == 'isReviewed') {
            vehicle.isReviewedModal = new emptyModal();
            vehicle.isReviewedModal.answer = vehicle.isReviewed;
            vehicle.isReviewedModal.group = vehicle['vehicleYear'].group;
            vehicle.isReviewedModal.key = 'isReviewedModal';
            unitJSON.push(vehicle.isReviewedModal)
          }
        });
      });
    }
    let trailerStartingIndex = this.dao.vehicleModel.VehiclesList.length + 1;
    if (this.dao.vehicleModel.trailerList && this.dao.vehicleModel.trailerList.length > 0) {
      this.dao.vehicleModel.trailerList.forEach((trailer, i) => {
        let tempTrailer = _.cloneDeep(trailer);
        if (trailer.zoneList && trailer.zoneList.length > 0) {
          let tempZone = tempTrailer.vehicleToZone;
          let tempZonePercent = tempTrailer.vehicleApplicableZonePercent;
          trailer.zoneList.forEach((z, j) => {
            if (j == 0) {
              trailer.vehicleToZone.answer = z.zone;
              trailer.vehicleApplicableZonePercent.answer = z.percentage;
            }
            else {
              let maxindex = j + 1;
              tempZone.answer = z.zone;
              tempZone.rowIndex = maxindex;
              tempZonePercent.answer = z.percentage;
              tempZonePercent.rowIndex = maxindex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(unitJSON);
              tempArray.push(tempZone);
              tempArray.push(tempZonePercent);
              unitJSON = _.cloneDeep(tempArray);
            }
          });
        }
        if (trailer.interestList && trailer.interestList.length > 0) {
          let tempaddInsuredInterestType = tempTrailer.addInsuredInterestType;
          let tempaddInsuredName = tempTrailer.addInsuredName;
          let tempaddInsuredStreetAddress = tempTrailer.addInsuredStreetAddress;
          let tempaddInsuredCity = tempTrailer.addInsuredCity;
          let tempaddInsuredState = tempTrailer.addInsuredState;
          let tempaddInsuredPostalCode = tempTrailer.addInsuredPostalCode;
          let tempaddInsuredLoanNumber = tempTrailer.addInsuredLoanNumber;
          trailer.interestList.forEach((z, j) => {
            let rIndex = 1 + uniqueAddInsured.findIndex((obj) => { return obj.type == z.Type && obj.name == z.Name });
            //each additional insured provider has its own unique row index
            if (rIndex <= 0) {
              uniqueAddInsured.push({ type: z.Type, name: z.Name });
              rIndex = 1 + uniqueAddInsured.findIndex((obj) => { return obj.type == z.Type && obj.name == z.Name });
            }
            if (j == 0) {
              trailer.addInsuredInterestType.answer = z.Type;
              trailer.addInsuredInterestType.rowIndex = rIndex;
              trailer.addInsuredName.answer = z.Name;
              trailer.addInsuredName.rowIndex = rIndex;
              trailer.addInsuredStreetAddress.answer = z.streetAddress;
              trailer.addInsuredStreetAddress.rowIndex = rIndex;
              trailer.addInsuredCity.answer = z.city;
              trailer.addInsuredCity.rowIndex = rIndex;
              trailer.addInsuredState.answer = z.state;
              trailer.addInsuredState.rowIndex = rIndex;
              trailer.addInsuredPostalCode.answer = z.zip;
              trailer.addInsuredPostalCode.rowIndex = rIndex;
              trailer.addInsuredLoanNumber.answer = z.loanNo;
              trailer.addInsuredLoanNumber.rowIndex = rIndex;
            }
            else {

              tempaddInsuredInterestType.answer = z.Type;
              tempaddInsuredInterestType.rowIndex = rIndex;
              tempaddInsuredName.answer = z.Name;
              tempaddInsuredName.rowIndex = rIndex;
              tempaddInsuredStreetAddress.answer = z.streetAddress;
              tempaddInsuredStreetAddress.rowIndex = rIndex;
              tempaddInsuredCity.answer = z.city;
              tempaddInsuredCity.rowIndex = rIndex;
              tempaddInsuredState.answer = z.state;
              tempaddInsuredState.rowIndex = rIndex;
              tempaddInsuredPostalCode.answer = z.zip;
              tempaddInsuredPostalCode.rowIndex = rIndex;
              tempaddInsuredLoanNumber.answer = z.loanNo;
              tempaddInsuredLoanNumber.rowIndex = rIndex;
              // clone deep add for duplictes
              let tempArray = _.cloneDeep(unitJSON);
              tempArray.push(tempaddInsuredInterestType);
              tempArray.push(tempaddInsuredName);
              tempArray.push(tempaddInsuredStreetAddress);
              tempArray.push(tempaddInsuredCity);
              tempArray.push(tempaddInsuredState);
              tempArray.push(tempaddInsuredPostalCode);
              tempArray.push(tempaddInsuredLoanNumber);
              unitJSON = _.cloneDeep(tempArray);
            }
          });
        }
        Object.keys(trailer).forEach(key => {
          if (key != 'group' && key != 'zoneList' && key != 'interestList' && key != 'isReviewed' && typeof (trailer[key]) == 'object') {
            if (key != 'vehicleToZone' && key != 'vehicleApplicableZonePercent' && key != 'addInsuredInterestType' && key != 'addInsuredName' && key != 'addInsuredStreetAddress' && key != 'addInsuredCity' && key != 'addInsuredState' && key != 'addInsuredPostalCode' && key != 'addInsuredLoanNumber') {
              trailer[key].rowIndex = i + trailerStartingIndex;
            }
            unitJSON.push(trailer[key]);
          }

          // setting the isreviewed and save to db for showing and hiding Save and Continue button in the screen 
          if (key == 'isReviewed') {
            trailer.isReviewedModal = new emptyModal();
            trailer.isReviewedModal.answer = trailer.isReviewed;
            trailer.isReviewedModal.group = trailer['vehicleYear'].group;
            trailer.isReviewedModal.key = 'isReviewedModal';
            unitJSON.push(trailer.isReviewedModal)
          }
        });
      });
    }
    if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill').length > 0) {
      let bulkdata = this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer?.filter(x => x.key == 'bulkEditRadiusCheckbox' || x.key == 'bulkEditCompCoverageCheckbox' || x.key == 'bulkEditCollCoverageCheckbox'
        || x.key == 'bulkEditGaragingCheckbox' || x.key == 'bulkEditCustomPartsCheckbox' || x.key == 'bulkEditInsuredLossPayeeCheckbox');
      if (bulkdata != null) {
        bulkdata.forEach(element => {
          unitJSON.push(element);
        });
      }
      this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer = _.cloneDeep(unitJSON);
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditRadiusCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditRadiusCheckbox')[0].answer = this.dao.VehicleBulkEditRadiusCheckbox;
      }
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCompCoverageCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCompCoverageCheckbox')[0].answer = this.dao.VehicleBulkEditCompCoverageCheckbox;
      }
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCollCoverageCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCollCoverageCheckbox')[0].answer = this.dao.VehicleBulkEditCollCoverageCheckbox;
      }
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditGaragingCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditGaragingCheckbox')[0].answer = this.dao.VehicleBulkEditGaragingCheckbox;
      }
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCustomPartsCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditCustomPartsCheckbox')[0].answer = this.dao.VehicleBulkEditCustomPartsCheckbox;
      }
      if (this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditInsuredLossPayeeCheckbox').length > 0) {
        this.dao.DOTList.questionnaire.filter(q => q.key == 'vehiclePrefill')[0].questionAnswer.filter(x => x.key == 'bulkEditInsuredLossPayeeCheckbox')[0].answer = this.dao.VehicleBulkEditInsuredLossPayeeCheckbox;
      }
    }
  }
  // ========= Bind Account Info ================
  public async bindAccountInfo(IsDeclined: boolean = false) {
    this.dao.IsAccountDOTBreadcrumpClicked = true;
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    await this.getDOTDetails(true, 'AccountInfo', IsDeclined);
    if (!IsDeclined) {
      this.checkEvaluatequestionaire('AccountInfo', true);
    }
  }
  // ========= Bind UW info ==============
  public async bindUW() {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    //will not save json to database
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, true, 'uw', 'dot', this.dao.IsWIPCall, false], "payloadData": this.dao.DOTList }
    return await this.getDOTDetailsService(payload).then((data: any) => {
      if (!data) {
        return;
      }
      this.dao.navigatePage = this.dao.finalSubmitPage;

      this.checkEvaluatequestionaire('AccountInfo', true);

      window.scrollTo(0, 300);

      if (this.dao.IsBackButtonClicked == true) {
        this.dao.IsBackButtonClicked = false;
      }
      return data;
    });
  }
  // ========= Bind Loss info ==============
  public async bindLossInfo() {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    //will not save json to database
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, true, 'losshistory', 'dot', this.dao.IsWIPCall, false], "payloadData": this.dao.DOTList }
    return await this.getDOTDetailsService(payload).then(async (data: any) => {
      if (!data) {
        return;
      }
      this.dao.navigatePage = this.dao.finalSubmitPage;
      if (this.dao.IsBackButtonClicked == true) {
        this.dao.IsBackButtonClicked = false;
      }
      return this.checkEvaluatequestionaire('LossHistory', true);

    });
  }
  // ========= Bind Vehicles =========
  public async bindVehicles() {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    //will not save json to database
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, true, 'vehicles', 'dot', this.dao.IsWIPCall, false], "payloadData": this.dao.DOTList }
    return await this.getDOTDetailsService(payload).then(async (data: any) => {
      if (!data) {
        return;
      }
      this.dao.navigatePage = this.dao.finalSubmitPage;     
      await this.checkEvaluatequestionaire(this.dao.PolicyTemplate, true);      
      this.dao.initialvehicleModel = _.cloneDeep(this.dao.vehicleModel);
      if (this.dao.IsBackButtonClicked == true) {
        this.dao.IsBackButtonClicked = false;
      }

    });
  }
  // ========= Save MGB ================
  public async bindDatafromWIP(event) {
    this.dao.IsEditRadiusSaved = true;
    this.dao.IsEditCompDeductibleSaved = true;
    this.dao.IsEditCollDeductibleSaved = true;
    this.dao.IsWIPCall = true;
    this.dao.IsDOTExistsInWIP = true;
    this.dao.PolicyTemplate = event.data.page;
    this.dao.vehicleModel.vehicleItem = new vehicleDetails();
    this.dao.DOTList = await this.getDOTNumber(true);
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("accountDot", event.data.dotNumber);
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("number", event.data.referenceNumber);
    if (this.dao.PolicyTemplate == 'AccountInfo') {
      await this.bindAccountInfo();
      this.dao.IsAccountDOTBreadcrumpClicked = true;
      // this.dao.IsUWBreadcrumpClicked = true;
      this.dao.IsLossHistoryBreadcrumpClicked = true;
      this.dao.IsVehicleBreadcrumpClicked = true;
      this.dao.IsDriverBreadcrumpClicked = true;
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    else if (this.dao.PolicyTemplate == 'uw') {
      await this.bindUW();
      // this.dao.IsUWBreadcrumpClicked = true;
      this.dao.IsLossHistoryBreadcrumpClicked = true;
      this.dao.IsVehicleBreadcrumpClicked = true;
      this.dao.IsDriverBreadcrumpClicked = true;
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
    }
    else if (this.dao.PolicyTemplate == 'losshistory') {
      await this.bindLossInfo();
      if (this.dao.DOTList.accountInfoQsnr.findQuestion("cargoIndicator").answer == 'No') {
        this.dao.IsLossHistoryBreadcrumpClicked = true;
      }
      this.dao.IsVehicleBreadcrumpClicked = true;
      this.dao.IsDriverBreadcrumpClicked = true;
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    else if (this.dao.PolicyTemplate == 'vehicles') {
      await this.bindVehicles();
      this.dao.IsVehicleBreadcrumpClicked = true;
      this.dao.IsDriverBreadcrumpClicked = true;
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    else if (this.dao.PolicyTemplate == 'driver') {
      await this.bindDriver();
      this.dao.IsDriverBreadcrumpClicked = true;
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    else if (this.dao.PolicyTemplate == 'product') {
      this.dao.IsAccountStateFreezed = true;
      await this.bindProduct(true);
      this.dao.IsProductBreadcrumpClicked = true;
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    else if (this.dao.PolicyTemplate == 'application') {
      this.dao.IsAccountStateFreezed = true;
      await this.bindApplicationInfo(true);
      this.dao.IsApplicationBreadcrumpClicked = true;
      this.dao.IsWIPCall = false;
    }
    this.dao.finalSubmitPage = this.dao.PolicyTemplate;
    this.dao.navigatePage = this.dao.finalSubmitPage;
    this.setInitialData();
    if (this.dao.IsPolicyDateNotified) {
      await this.bindAccountInfo(true);
      this.dao.PolicyTemplate = 'AccountInfo';
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: this.dao.DOTLookupStatusMsg[0] });
    }
    this.setActiveMenu();
  }
  //======== save the policy details ====
  public async save(isFromKarma: boolean = false) {
    let valid = await this.commonValidation();
    if (valid == true || isFromKarma) {
      await this.setQuestionnairesData();
      let getTemplateOrder = (this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate) && this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate).length > 0) ? this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate)[0].order : 0;
      let getFinalTemplateOrder = (this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage) && this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage).length > 0) ? this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage)[0].order : 0;
      let DotExists: boolean = false;
      if (getTemplateOrder <= getFinalTemplateOrder) {
        DotExists = true;
      }
      let currentTemplate = this.dao.PolicyTemplate;
      let navPage = this.dao.navigatePage;
      this.dao.MenuSavedDOTList = _.cloneDeep(this.dao.DOTList);
      if (currentTemplate == 'losshistory') {
        currentTemplate = 'loss';
      }
      else if (currentTemplate == 'vehicles') {
        currentTemplate = 'vehiclePrefill';
      }
      if (navPage == 'losshistory') {
        navPage = 'loss';
      }
      else if (navPage == 'vehicles') {
        navPage = 'vehiclePrefill';
      }
      if (this.dao?.DOTList?.finalSubmitPage == "AccountInfo") {
        _.remove(this.dao.DOTList['questionnaire'], function (n) {
          return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo';
        })
      }
      else if (this.dao?.DOTList?.finalSubmitPage == "uw") {
        _.remove(this.dao.DOTList['questionnaire'], function (n) {
          return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw';
        })
      }
      else if (this.dao?.DOTList?.finalSubmitPage == "losshistory") {
        if (currentTemplate == 'vehiclePrefill') {
          _.remove(this.dao.DOTList['questionnaire'], function (n) {
            return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw' && n.key != 'loss' && n.key != 'vehicle';
          })
        }
        else {
          _.remove(this.dao.DOTList['questionnaire'], function (n) {
            return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw' && n.key != 'loss';
          })
        }
      }
      else if (this.dao?.DOTList?.finalSubmitPage == "vehicles") {
        _.remove(this.dao.DOTList['questionnaire'], function (n) {
          return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw' && n.key != 'loss' && n.key != 'vehicle' && n.key != 'vehiclePrefill';
        })
      }
      else if (this.dao?.DOTList?.finalSubmitPage == "driver") {
        _.remove(this.dao.DOTList['questionnaire'], function (n) {
          return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw' && n.key != 'loss' && n.key != 'vehicle' && n.key != 'vehiclePrefill' && n.key != 'driver';
        })
      }
      else if (this.dao?.DOTList?.finalSubmitPage == "product") {
        _.remove(this.dao.DOTList['questionnaire'], function (n) {
          return n.key != currentTemplate && n.key != 'dot' && n.key != 'AccountInfo' && n.key != 'uw' && n.key != 'loss' && n.key != 'vehicle' && n.key != 'vehiclePrefill' && n.key != 'driver' && n.key != 'product';
        })
      }
      //will save json to database
      let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, DotExists, this.dao.PolicyTemplate, 'dot', false, true], "payloadData": this.dao.DOTList }
      const response = await this.SaveDetails(payload);
      this.setActiveMenu();
      return response;
    }
    else {
      window.scrollTo(0, 300);
      return valid;
    }
  }


  public checkApplicationResult(data): void {
    let jsonPathRef = require('jsonpath');
    let hasDeclinedNotification = false;
    let notifyData = jsonPathRef.query(data, '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusCode');
    if (!!notifyData && notifyData.filter(c => c == 'Decline').length > 0) {
      hasDeclinedNotification = true;
    }
    if (hasDeclinedNotification) {
      let searchQueryForDeclineObj = '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusDesc';
      let declineArrayList = jsonPathRef.query(data, searchQueryForDeclineObj);
      let declineObjPath = declineArrayList.find(c => c.includes('EvaluateSubmission'))
      let validationMessage = "";
      if (declineObjPath != null) {
        let declinePathArray = declineObjPath.split(".");
        if (!!declinePathArray && declinePathArray.length > 1) {
          let declineMsgQuery = "$..[?(@.key=='" + declinePathArray[0] + "')].questionAnswer[?(@.key=='" + declinePathArray[1] + "')].answers[?(@.datasource=='Agent')].answer"
          let declineMsgArray = jsonPathRef.query(data, declineMsgQuery);
          if (declineMsgArray != null && declineMsgArray.length > 0) {
            validationMessage = declineMsgArray[0];
          }
        }
      }
      if (!!!validationMessage) {
        this.appComponent.displayErrorPopupifAnyHitFails();
      } else {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: validationMessage, summary: "declineSaved" });
      }
    } else {
      let submissionNo = jsonPathRef.query(data, '$..questionnaire[?(@.key =="application")].questionAnswer[?(@.key =="submissionNumber")].answer')[0];
      if (!!submissionNo) {
        let details = 'Thank you for choosing Berkley Small Business Solutions for your transportation needs. This submission has been cleared for your agency. You will be receiving cleared submission confirmation email with the trucking application attached.'
        this.messageService.add({ key: 'confirmation', sticky: true, severity: 'success', data: details });
      } else {
        if (data.status == '500') {
          this.appComponent.displayErrorPopupifAnyHitFails();
        }
      }
    }
  }

  // ========= Get Loss History =====================
  public async getAgencyBranchCode(payload) {
    return await this.commonService.get('application', 'getAgencyandBranchCode', payload).toPromise();
  }
  // ============== Upload Document =============
  public async uploadDocument(ClientID, guid, fileName, docType) {
    let payload = { "urlData": [ClientID, guid, fileName, docType] }
    return await this.commonService.post('application', 'uploadDocument', payload).toPromise();
  }
  // ============ Save Datas===============
  public async SaveDetails(payload) {
    if (this.dao.NotAllowedToSubmit) {
      return false;
    }
    if (this.dao.IsPolicyDateNotified) {
      this.dao.IsPolicyDateNotified = false;
    }
    this.modifyDateObject(payload);
    this.dao.IsValidationCheck = false;
    let getTemplateOrder = (this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate) && this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate).length > 0) ? this.saveDatas.filter(s => s.template == this.dao.PolicyTemplate)[0].order : 0;
    let getFinalTemplateOrder = (this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage) && this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage).length > 0) ? this.saveDatas.filter(s => s.template == this.dao.finalSubmitPage)[0].order : 0;
    if ((getTemplateOrder >= getFinalTemplateOrder)
      || (this.saveDatas[getTemplateOrder].template == 'AccountInfo' && !this.dao.IsAccountDOTBreadcrumpClicked)
      || (this.saveDatas[getTemplateOrder].template == 'uw' && !this.dao.IsUWBreadcrumpClicked)
      || (this.saveDatas[getTemplateOrder].template == 'losshistory' && !this.dao.IsLossHistoryBreadcrumpClicked)
      || (this.saveDatas[getTemplateOrder].template == 'vehicles' && !this.dao.IsVehicleBreadcrumpClicked)
      || (this.saveDatas[getTemplateOrder].template == 'driver' && !this.dao.IsDriverBreadcrumpClicked)
      || (this.saveDatas[getTemplateOrder].template == 'product')
      || (this.saveDatas[getTemplateOrder].template == 'application')
    ) {
      this.dao.isSubmitForm = false;
      if (this.dao.PolicyTemplate == "AccountInfo") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data) {
            if (data.isStatusReceived != "" && data.isStatusReceived != null) {
              this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
            } else {
              this.dao.DOTList = data;
              this.dao.finalSubmitPage = data.finalSubmitPage;
              this.dao.navigatePage = this.dao.finalSubmitPage;
              await this.checkEvaluatequestionaire('uw');
              if (this.dao.DOTList.accountInfoQsnr.findQuestion("cargoIndicator")?.answer === 'Yes') {
                let isProductExists = this.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key =="product")]');
                if (isProductExists?.length > 0 && !!!this.dao["isBusinessClassChanged"]) {
                  await this.bindProduct();
                  this.dao.ProductModel.productDetails.flatDeductible.answer = this.dao.ProductModel.productDetails.flatDeductible.questionDefault[0].code;
                  await this.setProductData();
                }
              }
              window.scrollTo(0, 300);
              if (this.dao.isClickFromMenu && this.dao.DOTList.currentPage != 'Decline') {
                this.dao.navigatePage = this.dao.finalSubmitPage;
                this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              }
              this.dao.isSubmitForm = false;
              this.dao.isClickFromMenu = false;
              this.setInitialData();
              this.SetAddressInVehicleAndProduct();
              if (this.dao.IsBackButtonClicked == true) {
                this.dao.IsBackButtonClicked = false;
              }
              return data;
            }
          }
        });
      }
      else if (this.dao.PolicyTemplate == "uw") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data) {
            if (data.isStatusReceived != "" && data.isStatusReceived != null) {
              this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
            } else {
              this.dao.DOTList = data;
              this.dao.finalSubmitPage = data.finalSubmitPage;
              this.dao.navigatePage = this.dao.finalSubmitPage;
              let nextPage = 'LossHistory';
              if (this.dao.isClickFromMenu && this.dao.ClickedPageFromMenuPage == "AccountInfo")
                nextPage = "AccountInfo"; 
              await this.checkEvaluatequestionaire(nextPage);
              if (this.dao.isClickFromMenu && !this.dao.IsBackButtonClicked) {
                this.dao.navigatePage = this.dao.finalSubmitPage;
                this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              }
              this.dao.isSubmitForm = false;
              this.dao.isClickFromMenu = false;
              this.setInitialData();
              if (this.dao.IsBackButtonClicked == true) {
                this.dao.IsBackButtonClicked = false;
                this.dao.navigatePage = this.dao.finalSubmitPage;                
                this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
                this.dao.IsAccountDOTBreadcrumpClicked = true;
              }
            }
          }
        });
      }
      else if (this.dao.PolicyTemplate == "losshistory") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data.isStatusReceived != "" && data.isStatusReceived != null) {
            this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
          } else {
            
            // this.dao.PolicyTemplate = "vehicles"
            this.dao.DOTList = data;
            this.dao.finalSubmitPage = data.finalSubmitPage;
            this.dao.navigatePage = this.dao.finalSubmitPage;
            let nextPage = 'vehicles';
            if (this.dao.isClickFromMenu && this.dao.ClickedPageFromMenuPage == "uw")
              nextPage = "losshistory";
            await this.checkEvaluatequestionaire(nextPage);            
            window.scrollTo(0, 300);
            // this.dao.finalSubmitPage = this.dao.PolicyTemplate;
            if (this.dao.isClickFromMenu) {
              this.dao.navigatePage = this.dao.finalSubmitPage;
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
            }
            this.dao.isSubmitForm = false;
            this.dao.isClickFromMenu = false;                        

            if (this.dao.IsBackButtonClicked == true) {
              this.dao.IsBackButtonClicked = false;
              this.dao.navigatePage = this.dao.finalSubmitPage;              
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              this.dao.IsUWBreadcrumpClicked = true;
            }
          }

        });
      }
      else if (this.dao.PolicyTemplate == "vehicles") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data.isStatusReceived != "" && data.isStatusReceived != null) {
            this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
          } else {
            this.dao.DOTList = data;
            this.dao.finalSubmitPage = data.finalSubmitPage;
            this.dao.navigatePage = this.dao.finalSubmitPage;
            if (data.questionnaire.filter(x => x.key == 'product').length > 0) {
              await this.bindProduct();
            }            
            await this.checkEvaluatequestionaire('driver');
            if (this.dao.isClickFromMenu) {
              this.dao.navigatePage = this.dao.finalSubmitPage;
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
            }
            this.dao.isSubmitForm = false;
            this.dao.isClickFromMenu = false;
            await this.setInitialData();
            if (this.dao.IsBackButtonClicked == true) {
              this.dao.IsBackButtonClicked = false;
              this.dao.navigatePage = this.dao.finalSubmitPage;              
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              this.dao.IsLossHistoryBreadcrumpClicked = true;
            }
          }

        });
      }
      else if (this.dao.PolicyTemplate == "driver") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data.isStatusReceived != "" && data.isStatusReceived != null) {
            this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
          } else {
            this.dao.DOTList = data;
            this.dao.finalSubmitPage = data.finalSubmitPage;
            this.dao.navigatePage = this.dao.finalSubmitPage;
            await this.bindProduct(false);
            let nextPage = 'product';
            if (this.dao.isClickFromMenu && this.dao.ClickedPageFromMenuPage == "vehicles")
              nextPage = "driver";
            await this.checkEvaluatequestionaire(nextPage);
            if (this.dao.isClickFromMenu) {
              this.dao.navigatePage = this.dao.finalSubmitPage;
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
            }
            this.dao.isSubmitForm = false;
            this.dao.isClickFromMenu = false;
            this.setInitialData();

            if (this.dao.IsBackButtonClicked == true) {
              this.dao.IsBackButtonClicked = false;
              this.dao.navigatePage = this.dao.finalSubmitPage;              
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              this.dao.IsVehicleBreadcrumpClicked = true;
            }
          }
        });
      }
      else if (this.dao.PolicyTemplate == "product") {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (data: any) => {
          if (data.isStatusReceived != "" && data.isStatusReceived != null) {
            this.appComponent.duplicatePopup ? this.appComponent.duplicatePopup() : '';
          } else {
            this.dao.IsAccountStateFreezed = true;
            if (data != null) {
              this.dao.DOTList = data;
              this.dao.finalSubmitPage = data.finalSubmitPage;
              this.dao.navigatePage = this.dao.finalSubmitPage;              
              await this.checkEvaluatequestionaire('application');
              if (this.dao.isClickFromMenu) {
                this.dao.navigatePage = this.dao.finalSubmitPage;
                this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              }
              this.dao.isSubmitForm = false;
              this.dao.isClickFromMenu = false;
              this.setInitialData();

              if (this.dao.IsBackButtonClicked == true) {
                this.dao.IsBackButtonClicked = false;
                this.dao.navigatePage = this.dao.finalSubmitPage;                
                this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
                this.dao.IsDriverBreadcrumpClicked = true;
              }
            }
          }
        });
      }
      else {
        window.scrollTo(0, 300);
        return true;
      }
    }
    else {
      if (this.dao.PolicyTemplate == 'vehicles') {
        this.constructGaragingAddress();
      }
      if ((this.dao.PolicyTemplate == 'AccountInfo') || this.dao.isClickFromMenu && this.dao.ClickedPageFromMenuPage == 'AccountInfo')
         {
        return await this.savePayLoadAndGetMGBInfoFromService(payload).then(async (MGBdata: any) => {
          if (MGBdata) {
            let IsDeclinedDOT = false;
            this.dao.DOTList.msgStatus = MGBdata.msgStatus;
            let jsonPathRef = require('jsonpath');
            let searchQueryForDeclineObj = '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusDesc';
            let declientArrayList = jsonPathRef.query(MGBdata, searchQueryForDeclineObj);
            let declineObjPath;
            let notifyData = jsonPathRef.query(MGBdata, '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusCode');
            if (notifyData && notifyData.length > 0 && notifyData.filter(c => c == 'Decline').length > 0) {
              IsDeclinedDOT = true;
            }
            if (declientArrayList && declientArrayList.length > 0) {
              declineObjPath = declientArrayList.filter(C => C.includes('EvaluateSubmission')).length > 0 ? declientArrayList.filter(C => C.includes('EvaluateSubmission'))[0] : declientArrayList[0];
            }
            if (declineObjPath != null) {
              let declinePathArray = declineObjPath.split(".");
              if (declinePathArray != null && declinePathArray.length > 1) {
                let declineMsgQuery = "$..[?(@.key=='" + declinePathArray[0] + "')].questionAnswer[?(@.key=='" + declinePathArray[1] + "')].answers[?(@.datasource=='Agent')].answer"
                let declineMsgArray = jsonPathRef.query(MGBdata, declineMsgQuery);
                if (declinePathArray[1] == 'Cab Not Dot Found') {
                  declineMsgArray = [];
                  declineMsgArray.push(MGBdata.questionnaire[0].questionAnswer[0].answers.filter(c => c.datasource == 'Agent')[0].answer);
                }
                if (declineMsgArray != null && declineMsgArray.length > 0) {
                  this.dao.IsPolicyDateNotified = false;
                  if (MGBdata.questionnaire?.filter(c => c.key == 'EvaluatePolicyHolder')[0]?.questionAnswer?.filter(s => s.key == 'PolicyEffectiveDate' && s.status == 'Notify')?.length > 0) {
                    this.dao.IsPolicyDateNotified = true;
                  }
                  this.dao.DOTLookupStatusMsg.push(declineMsgArray[0]);
                }
              }
            }
            if (this.dao.DOTLookupStatusMsg.length > 0 && IsDeclinedDOT) {
              document.body.style.pointerEvents = "none";
              let msg = this.dao.DOTLookupStatusMsg;
              this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: msg[0] });
              this.dao.DOTLookupStatusMsg = [];
              this.dao.isDOTValid = false;
              this.dao.DOTList = MGBdata;
            }
            else if (this.dao.isClickFromMenu) {
              this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
              this.dao.isSubmitForm = false;
              this.dao.isClickFromMenu = false;
            }
            else {
              this.dao.PolicyTemplate = 'uw';
            }
            if (this.dao.finalSubmitPage == 'product' || this.dao.finalSubmitPage == 'application') {
              await this.SetPremiseAddress(MGBdata);                            
            }
            else if (this.dao.finalSubmitPage == 'vehicles' || this.dao.finalSubmitPage == 'driver') {                            
            }
            return true;
          }
        });
      }
      if (this.dao.isClickFromMenu) {
        this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
        this.dao.isSubmitForm = false;
        this.dao.isClickFromMenu = false;
      }
      else {
        this.dao.PolicyTemplate = this.saveDatas[getTemplateOrder].template;
      }
      this.dao.DOTList = _.cloneDeep(this.dao.MenuSavedDOTList);
      this.setInitialData();
      window.scrollTo(0, 300);
      return true;
    }
  }
  public modifyDateObject(payload): void {
    let dateFields = this.getObjUsingJpath(payload, '$..[?(@.type =="Date" || @.key == "dob")]');
    if (!!dateFields && dateFields.length > 0) {
      dateFields.forEach(date => {
        if (!!date.answer && !isNaN(new Date(this.datepipe.transform((date.answer), 'MM-dd-yyyy')).getTime())) {
          date.answer = this.datepipe.transform(new Date(this.datepipe.transform((date.answer), 'MM-dd-yyyy')), 'yyyy-MM-dd');
        }
      })
    }
  }
  //========get object based on query=======
  public getObjUsingJpath(obj, query) {
    let jsonPathRef = require('jsonpath');
    if (obj != null) {
      let jPathObj = jsonPathRef.query(obj, query);
      return jPathObj;
    }
    return;
  }
  // ========= Get MGB Info service =====================
  public async savePayLoadAndGetMGBInfoFromService(payload) {
    return await this.commonService.post('policy-holder', 'getDOTDetails', payload)
      .pipe(
        map(
          (detail: ResponseContainer | any) => {
            let response = ResponseContainer.fromJSON(detail);
            this.dao.DOTList = response;
            this.dotSubmissionDetails.next(response);
            return response;
          })
      ).toPromise();
  }
  // ========= Save Details =====================
  public async SavePayloadToAPI(payload) {
    let Details = this.saveDatas.filter(c => c.template == this.dao.PolicyTemplate)[0];
    return await this.commonService.post(Details.component, Details.functionality, payload).toPromise();
  }
  // ========= Check date validation =====================
  public checkDateValidation() {
    let effectiveDateQ: QuestionAnswer = (<ResponseContainer>this.dao.DOTList).accountInfoQsnr.findQuestion("policyEffectiveDate");
    let minEffDate: Date = this.getCorrectDate(effectiveDateQ.minimum);
    let maxEffDate: Date = this.getCorrectDate(effectiveDateQ.maximum);
    let effDate = this.getCorrectDate(effectiveDateQ.answer);
    let minExpDate: Date = new Date(minEffDate.getFullYear() + 1, minEffDate.getMonth(), minEffDate.getDate());
    let maxExpDate: Date = new Date(maxEffDate.getFullYear() + 1, maxEffDate.getMonth(), maxEffDate.getDate());
    let expirationDateQ: QuestionAnswer = (<ResponseContainer>this.dao.DOTList).accountInfoQsnr.findQuestion("policyExpirationDate");
    let expDate = this.getCorrectDate(expirationDateQ.answer);
    if (minEffDate <= effDate && maxEffDate >= effDate &&
      minExpDate <= expDate && maxExpDate >= maxExpDate) {
      return true;
    }
    return false;
  }


  // ========= Numbers only input =====================
  public numbersOnly(e, pageFrom: string = '') {
    const pattern = /^[0-9]/;
    var key = e.keyCode || e.which;
    if (key == 8 || key == 9 || key == 11 || key == 27
      || (key == 39 && pageFrom != 'loss' && (e.currentTarget.name != 'txtVCYear' && e.currentTarget.name != 'fiftyRadiusPercentage' && e.currentTarget.name != 'fifty200RadiusPercentage' && e.currentTarget.name != 'liabIndividualLosses'))
      || (key == 46 && pageFrom != 'loss' && pageFrom != 'vehicle' && pageFrom != 'dot' && e.currentTarget.name != 'liabIndividualLosses')) {
      return true;
    }
    let inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  }

  // ========= Validate mail id =====================
  public validateEmails(string) {
    if (!(string == null || string == undefined || string.trim() == "")) {
      var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      var result = string.replace(/\s/g, "").split(/,|;/);
      for (var i = 0; i < result.length; i++) {
        if (!regex.test(result[i])) {
          return false;
        }
      }
      return true;
    }
    else {
      return false;
    }
  }

  // ============= Get Work in Progress
  public async getWorkInProgress() {
    let payload = { "urlData": [this.dao.userMail] }
    if (this.dao.userMail != null && this.dao.userMail != '') {
      return await this.commonService.get('workInProgress', 'getWorkInProgress', payload).toPromise().then((data: any) => {
        this.dao.SubmissionList = data as workInProgress[];
      });
    }
  }
  // ============= Get Garaging Address ==========
  public createNewGaragingAddressFromAccountAddress(group: string = ''): emptyModal {
    let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
    let accountInfoQsnrValue = new PolicyHolderValueParser(accountInfoQsnr);
    let garagingAddress: emptyModal = new emptyModal();
    garagingAddress.key = 'vehicleGaragingAddress';
    garagingAddress.answer = accountInfoQsnrValue.accountAddress.toString();
    garagingAddress.group = group != '' ? group : 'vehicle';
    return garagingAddress;
  }

  // ========= Cancel my submission=====================
  public async CancelmySubmission() {
    let dotNumber = this.dao.DOTList.dotQsnr.findQuestion("accountDot")?.answer;
    let dotOrRefNumber = this.dao.DOTList.dotQsnr.findQuestion("number")?.answer;
    if (!!!dotOrRefNumber)
      dotOrRefNumber = dotNumber
    let payload = { "urlData": [dotOrRefNumber, this.dao.userMail, 'Cancelled'] }
    return await this.commonService.get('Policy', 'CancelPolicy', payload).toPromise().then((data: any) => {
      this.dao.PolicyTemplate = '';
      this.dao.PolicySubTemplate = 'newsubmission';
      this.dao.vehicleModel = new VehicleModel();
      this.dao.ProductModel = new ProductModel();
      window.scrollTo(0, 0);
    });
  }
  // ========= Add the vehicle=====================
  public async addVehicle(type, from: string = '') {
    let isAddVehicleBtn = this.isShowActinBtn('vehicle');
    let isAddTrailerBtn = this.isShowActinBtn('trailer');
    if (this.dao.isTrailer && this.dao.vehicleModel.trailerList.length > 0 && isAddTrailerBtn) {
      this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerList.length - 1] = _.cloneDeep(this.dao.vehicleModel.vehicleItem);
    }
    else if (!this.dao.isTrailer && this.dao.vehicleModel.VehiclesList.length > 0 && isAddVehicleBtn) {
      this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.VehiclesList.length - 1] = _.cloneDeep(this.dao.vehicleModel.vehicleItem);
    }

    var lastVehicleIndex = 0
    this.dao.vehicleModel.VehiclesList.forEach((vehicle, index) => {
      Object.keys(vehicle).forEach(tempKey => {
        if (typeof (vehicle[tempKey]) == 'object') {
          vehicle[tempKey].group = ('VEH-' + (index + 1));
        }
      });
      lastVehicleIndex = index + 1;
    });
    var lastTrailerIndex = 0
    this.dao.vehicleModel.trailerList.forEach((trailer, index) => {
      Object.keys(trailer).forEach(tempKey => {
        if (typeof (trailer[tempKey]) == 'object') {
          trailer[tempKey].group = ('TRA-' + (index + 1));
        }
      });
      lastTrailerIndex = index + 1;
    });

    let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
    let accountInfoQsnrValue = new PolicyHolderValueParser(accountInfoQsnr);

    var newVehicle = _.cloneDeep(this.dao.vehicleModel.vehicleDefault);
    newVehicle.vehicleYear.answer = "";
    newVehicle.vehicleMake.answer = "";
    newVehicle.vehicleModel.answer = "";
    newVehicle.vehicleVin.answer = "";
    newVehicle.vehicleGaragingAddress = this.createNewGaragingAddressFromAccountAddress('VEH-' + (this.dao.vehicleModel.VehiclesList.length + 1));
    newVehicle.vehicleGarageState.answer = accountInfoQsnrValue.accountAddress.state;
    newVehicle.vehicleGarageStreetAddress.answer = accountInfoQsnrValue.accountAddress.street;
    newVehicle.vehicleGarageCity.answer = accountInfoQsnrValue.accountAddress.city;
    newVehicle.vehicleGarageState.answer = accountInfoQsnrValue.accountAddress.state;
    newVehicle.vehicleGaragePostalCode.answer = accountInfoQsnrValue.accountAddress.postalCode;
    newVehicle.vehicleType.answer = type != 'vehicle' ? 'Unknown' : '';
    newVehicle.zoneList = [{ 'zone': '', 'percentage': '', 'helpText': '' }];
    newVehicle.interestList = [{ 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' }];
    Object.keys(newVehicle).forEach(tempKey => {
      if (typeof (newVehicle[tempKey]) == 'object') {
        newVehicle[tempKey].group = type == 'vehicle' ? ('VEH-' + (lastVehicleIndex + 1)) : ('TRA-' + (lastTrailerIndex + 1));
        if (newVehicle[tempKey].questionOptions != null && newVehicle[tempKey].questionOptions.length > 0) {
          newVehicle[tempKey].questionOptions = []; //empty question options to reduce payload size
        }
      }
    });
    if (type == 'trailer') {
      this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerList.length;
      if (this.dao.vehicleModel.trailerList.length > 0 && this.dao.vehicleModel.trailerList.every(item => item.isChecked)) {
        newVehicle.isChecked = true;
      }
      this.dao.vehicleModel.trailerList.push(newVehicle)
      this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.vehicleIndex + (!this.dao.isTrailer && this.dao.vehicleModel.vehiclePage == 3 ? 1 : 0);
    }
    else {
      this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.VehiclesList.length;
      if (this.dao.vehicleModel.VehiclesList.length > 0 && this.dao.vehicleModel.VehiclesList.every(item => item.isChecked)) {
        newVehicle.isChecked = true;
      }
      this.dao.vehicleModel.VehiclesList.push(newVehicle)
      this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerIndex + (this.dao.isTrailer && this.dao.vehicleModel.vehiclePage == 3 ? 1 : 0);
    }
    this.dao.vehicleModel.vehiclePage = 1
    this.dao.vehicleModel.vehicleItem = type == 'vehicle' ? this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex] : this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex];
    this.dao.isTrailer = type != 'vehicle';
  }
  // ========= Get Post Registration =====================
  public async postRegistration() {
    this.dao.RegistrationModal.adminEmail = this.dao.userMail;
    this.dao.RegistrationModal.email = this.dao.RegistrationModal.email.trim();
    let payload = { "urlData": [this.dao.userCompany, this.dao.RegistrationModal.email] };
    return await this.commonService.get('Login', 'CheckUser', payload).subscribe((event: any) => {
      if (event && !event.isSuccess) {
        let payload = { "urlData": [this.dao.userCompany, this.dao.RegistrationModal.agentCode], "payloadData": this.dao.RegistrationModal }
        this.registration(payload).then(async (event: any) => {
          if (event && event.isSuccess == true) {
            this.appComponent.registrationSuccessPopup(event?.responseText);
            await this.GetAllUsers();
            return true;
          }
          else {
            this.appComponent.registrationMsgPopup(event?.responseText);

          }
          return event.responseText;
        });
      }
      else if (event && event.isSuccess) {
        this.appComponent.registrationMsgPopup(event?.responseText);
        return event.responseText;
      }
    });
  }
  // ========= register the agent ===========
  public async registration(payload) {
    return await this.commonService.post('Registration', 'PostRegistration', payload).toPromise();
  }
  // ========= Check User Claims =====================
  public async CheckUserClaims(mail) {
    let payload = { "urlData": [this.dao.userCompany, mail] };
    let event: HttpResults = await this.commonService.get('Login', 'CheckUser', payload).toPromise();
    if (event && event.isSuccess == false) {
      this.appComponent.registrationMsgPopup(event?.responseText, true);
    }
    else {
      this.dao.UserRole = event?.role;
      this.dao.IsEndorsementActive = event?.isEndorsementActive;
      this.dao.IsInternalUser = event?.isInternalUser;
      this.dao.userAgencyCode = event?.agencyCode;
    }

  }
  // ============= Get Agency List ==========
  public async GetAgencyList(query) {
    let payload = { "urlData": [query] };
    return await this.commonService.get('Registration', 'GetAgencyName', payload).subscribe((event: any) => {
      if (event && event.status != 500) {
        this.dao.RegistrationModal.AgencyCodeList = [];
        this.dao.RegistrationModal.AgencyList = event;
        if (this.dao.RegistrationModal.AgencyList.code != null && this.dao.RegistrationModal.AgencyList.code != undefined) {
          this.dao.RegistrationModal.AgencyCodeList.push(this.dao.RegistrationModal.AgencyList.code);
        }
      }
    });
  }

  public async bindDriver(from: string = '') {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    if (from == '') {
      await this.getDOTDetails(true, 'driver');
      this.checkEvaluatequestionaire(this.dao.PolicyTemplate, from == '');
    }
    if (this.dao.IsBackButtonClicked == true) {
      this.dao.IsBackButtonClicked = false;
    }
  }
  public async bindProduct(isWIP: boolean = false) {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    console.log(isWIP, 'isWIP')
    if (isWIP == true) {
      await this.getDOTDetails(true, 'product');
      this.checkEvaluatequestionaire(this.dao.PolicyTemplate, isWIP);
    }

    let ProductModelObj = '$..questionnaire[?(@.key =="product")]'
    let getProductData = this.getObjUsingJpath(this.dao.DOTList, ProductModelObj)[0];
    this.dao.ProductModel.productDetails = new ProductDetails();
    let tempProductModel = new ProductDetails();
    if (getProductData) {
      Object.keys(tempProductModel).forEach(key => {
        let arrayData = _.cloneDeep(this.getObjUsingJpath(getProductData, '$..[?(@.key =="' + key + '")]'));
        if (arrayData && arrayData.length > 0) {
          tempProductModel[key] = _.cloneDeep(arrayData[0]);
        }
        if (tempProductModel[key].answer && tempProductModel[key].questionDefault && tempProductModel[key].questionDefault.length > 0 && tempProductModel[key].questionDefault[0].code && tempProductModel[key].questionOptions && tempProductModel[key].questionOptions.length > 0) {
          if (!tempProductModel[key].questionOptions.find(x => x?.code == tempProductModel[key].answer)) {
            tempProductModel[key].answer = tempProductModel[key].questionDefault[0].code;
          }
        }
        if ((tempProductModel[key].answer == null || tempProductModel[key].answer == "") && tempProductModel[key].questionDefault && tempProductModel[key].questionDefault.length > 0 && tempProductModel[key].questionDefault[0].code && tempProductModel[key].questionDefault[0].code != '') {
          tempProductModel[key].answer = tempProductModel[key].questionDefault[0].code;
        }
        this.dao.ProductModel.productDetails[key] = tempProductModel[key];
      });
    }
    this.dao.showingGlTab = this.dao.ProductModel.productDetails.glIncluded.answer == 'Yes' ? true : false;

    await this.BindGLForProduct('');
    await this.BindcargoForProduct('');

    let getproductdata = '$..questionnaire[?(@.key =="product")].questionAnswer';
    let getproductdataList = _.max(this.getObjUsingJpath(this.dao.DOTList, getproductdata));

    let umPedDefault = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="umPdDed")].questionDefault[0].code'
    this.dao.ProductModel.umPdDedDefaultValue = this.getObjUsingJpath(this.dao.DOTList, umPedDefault)[0];

    let uimbiDefault = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="uimBi")].questionDefault[0].code'
    this.dao.ProductModel.uimBiDefaultValue = this.getObjUsingJpath(this.dao.DOTList, uimbiDefault)[0];

    if (this.dao.AddAnotherInsuredForCommercialAuto.length == 0) {

      let getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="insurableInterestName")].rowIndex';
      let getMaxRowIndex = _.max(this.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.dao.AddAnotherInsuredForCommercialAuto.push({
          'Name': getproductdataList?.filter(f => f.key == 'insurableInterestName' && f.rowIndex == i)[0]?.answer,
          'Street': getproductdataList?.filter(f => f.key == 'insurableInterestStreet' && f.rowIndex == i)[0]?.answer,
          'City': getproductdataList?.filter(f => f.key == 'insurableInterestCity' && f.rowIndex == i)[0]?.answer,
          'State': getproductdataList?.filter(f => f.key == 'insurableInterestState' && f.rowIndex == i)[0]?.answer,
          'PostalCode': getproductdataList?.filter(f => f.key == 'insurableInterestPostalCode' && f.rowIndex == i)[0]?.answer,
        });
      }
      this.dao.initialAddAnotherInsuredForCommercialAuto = _.cloneDeep(this.dao.AddAnotherInsuredForCommercialAuto)
    }
    if (this.dao.AddAnotherWaiverForCommercialAuto.length == 0) {
      let getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="autoWavierOfSubrogationName")].rowIndex';
      let getMaxRowIndex = _.max(this.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.dao.AddAnotherWaiverForCommercialAuto.push({
          'Name': getproductdataList?.filter(f => f.key == 'autoWavierOfSubrogationName' && f.rowIndex == i)[0]?.answer
        })
      }
      this.dao.initialAddAnotherWaiverForCommercialAuto = _.cloneDeep(this.dao.AddAnotherWaiverForCommercialAuto)
    }
    if (this.dao.AddAnotherInsuredForCargo.length == 0) {

      let getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="cargoInsuredName")].rowIndex';
      let getMaxRowIndex = _.max(this.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.dao.AddAnotherInsuredForCommercialAuto.push({
          'Name': getproductdataList?.filter(f => f.key == 'cargoInsuredName' && f.rowIndex == i)[0]?.answer,
          'Street': getproductdataList?.filter(f => f.key == 'cargoInsuredStreet' && f.rowIndex == i)[0]?.answer,
          'City': getproductdataList?.filter(f => f.key == 'cargoInsuredCity' && f.rowIndex == i)[0]?.answer,
          'State': getproductdataList?.filter(f => f.key == 'cargoInsuredState' && f.rowIndex == i)[0]?.answer,
          'PostalCode': getproductdataList?.filter(f => f.key == 'cargoInsuredPostalCode' && f.rowIndex == i)[0]?.answer,
        });
      }
      this.dao.InitialAddAnotherInsuredForCargo = _.cloneDeep(this.dao.AddAnotherInsuredForCargo)
    }
    if (this.dao.AddAnotherNamedIndividual.length == 0) {
      let getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="namedIndividual")].rowIndex';
      let getMaxRowIndex = _.max(this.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.dao.AddAnotherNamedIndividual.push({
          'Name': getproductdataList?.filter(f => f.key == 'namedIndividual' && f.rowIndex == i)[0]?.answer
        })
      }
      this.dao.initialAddAnotherNamedIndividual = _.cloneDeep(this.dao.AddAnotherNamedIndividual)
    }
    // set initial data of products
    this.dao.initialProductModel = _.cloneDeep(this.dao.ProductModel);
    this.dao.initialProductQuestionnaireAnswers = _.cloneDeep(this.dao.DOTList?.questionnaire?.find(q => q.key === 'product'));
    this.dao.initialAddProductAnotherLocation = _.cloneDeep(this.dao.AddProductAnotherLocation);
    this.dao.initialAddAnotherInsured = _.cloneDeep(this.dao.AddAnotherInsured);
    this.dao.initialAddAnotherWaiver = _.cloneDeep(this.dao.AddAnotherWaiver);
    this.dao.initialAddAnotherInsuredForCommercialAuto = _.cloneDeep(this.dao.AddAnotherInsuredForCommercialAuto);
    this.dao.initialAddAnotherWaiverForCommercialAuto = _.cloneDeep(this.dao.AddAnotherWaiverForCommercialAuto);
    this.dao.initialAddAnotherNamedIndividual = _.cloneDeep(this.dao.AddAnotherNamedIndividual);
    if (this.dao.IsBackButtonClicked == true) {
      this.dao.IsBackButtonClicked = false;
    }
  }
  public async BindGLForProduct(type) {
    let ProductModelObj = '$..questionnaire[?(@.key =="product")]'
    let getproductdata = '$..questionnaire[?(@.key =="product")].questionAnswer';
    let getproductdataList = _.max(this.getObjUsingJpath(this.dao.DOTList, getproductdata));

    if (type == 'GLRemoved') {
      this.dao.AddProductAnotherLocation = [];
      this.dao.AddAnotherInsured = [];
      this.dao.AddAnotherWaiver = [];
      this.dao.ProductModel.productDetails.premiseStreet.answer = '';
      this.dao.ProductModel.productDetails.premiseCity.answer = '';
      this.dao.ProductModel.productDetails.premiseState.answer = '';
      this.dao.ProductModel.productDetails.premisePostalCode.answer = '';
      this.dao.ProductModel.productDetails.payrollAmount.answer = '';
      this.dao.ProductModel.productDetails.additionalInsuredName.answer = '';
      this.dao.ProductModel.productDetails.glWavierOfSubrogationName.answer = '';
      this.dao.ProductModel.productDetails.empBenefitsLiab.answer = '';
      this.dao.ProductModel.productDetails.glWavierOfSubrogation.answer = '';
      this.dao.ProductModel.productDetails.additionalInsuredIncludedParent.answer = '';

      let empBenefitsLiabDefault = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="empBenefitsLiab")].questionDefault[0].code'
      this.dao.ProductModel.productDetails.empBenefitsLiab.answer = this.getObjUsingJpath(this.dao.DOTList, empBenefitsLiabDefault)[0];

    }

    if (this.dao.AddProductAnotherLocation.length == 0) {

      let getLocationMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="premiseStreet")].rowIndex';
      let getLocationMaxRowIndex = _.max(this.getObjUsingJpath(this.dao.DOTList, getLocationMaxRow));


      for (var i = 1; i <= getLocationMaxRowIndex; i++) {
        if (getproductdataList?.filter(f => f.key == 'payrollAmount' && f.rowIndex == i)[0]?.answer == "" && type != 'GLRemoved') {
          this.dao.AddProductAnotherLocation.push({
            'premiseStreet': getproductdataList?.filter(f => f.key == 'premiseStreet' && f.rowIndex == i)[0]?.answer
            , 'premiseCity': getproductdataList?.filter(f => f.key == 'premiseCity' && f.rowIndex == i)[0]?.answer
            , 'premiseState': getproductdataList?.filter(f => f.key == 'premiseState' && f.rowIndex == i)[0]?.answer
            , 'premisePostalCode': getproductdataList?.filter(f => f.key == 'premisePostalCode' && f.rowIndex == i)[0]?.answer
            // Only the first payrollAmount will have the QuestionDefault, so we consider rowIndex = 1 to have the code value
            , 'payrollAmount': Number(getproductdataList?.filter(f => f.key == 'payrollAmount' && f.rowIndex == 1)[0]?.questionDefault[0].code.toString())
            , 'premiseLocation': ''
          })
        } else if (type == 'GLRemoved') {
          this.dao.AddProductAnotherLocation.push({
            'premiseStreet': getproductdataList?.filter(f => f.key == 'premiseStreet' && f.rowIndex == i)[0]?.answer
            , 'premiseCity': getproductdataList?.filter(f => f.key == 'premiseCity' && f.rowIndex == i)[0]?.answer
            , 'premiseState': getproductdataList?.filter(f => f.key == 'premiseState' && f.rowIndex == i)[0]?.answer
            , 'premisePostalCode': getproductdataList?.filter(f => f.key == 'premisePostalCode' && f.rowIndex == i)[0]?.answer
            // Only the first payrollAmount will have the QuestionDefault, so we consider rowIndex = 1 to get the code value
            , 'payrollAmount': Number(getproductdataList?.filter(f => f.key == 'payrollAmount' && f.rowIndex == 1)[0]?.questionDefault[0].code.toString())
            , 'premiseLocation': ''
          })
          i = getLocationMaxRowIndex;
        }
        else {
          this.dao.AddProductAnotherLocation.push({
            'premiseStreet': getproductdataList?.filter(f => f.key == 'premiseStreet' && f.rowIndex == i)[0]?.answer
            , 'premiseCity': getproductdataList?.filter(f => f.key == 'premiseCity' && f.rowIndex == i)[0]?.answer
            , 'premiseState': getproductdataList?.filter(f => f.key == 'premiseState' && f.rowIndex == i)[0]?.answer
            , 'premisePostalCode': getproductdataList?.filter(f => f.key == 'premisePostalCode' && f.rowIndex == i)[0]?.answer
            , 'payrollAmount': Number(getproductdataList?.filter(f => f.key == 'payrollAmount' && f.rowIndex == i)[0]?.answer.toString())
            , 'premiseLocation': getproductdataList?.filter(f => f.key == 'premiseLocation' && f.rowIndex == i)[0]?.answer
          })
        }
      }
      this.dao.initialAddProductAnotherLocation = _.cloneDeep(this.dao.AddProductAnotherLocation);
      let premiseStateValidation = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="premiseState")].questionInfoText'
      this.dao.PremiseStateValidation = this.getObjUsingJpath(this.dao.DOTList, premiseStateValidation)[0];

      let PremiseStateDefault = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="payrollAmount")].questionDefault[0].code'
      this.dao.ProductModel.PremiseStateDefaultValue = this.getObjUsingJpath(this.dao.DOTList, PremiseStateDefault)[0];
      if (this.dao.ProductModel.PremiseStateDefaultValue != undefined && this.dao.ProductModel.PremiseStateDefaultValue != '') {
        this.dao.ProductModel.PremiseStateDefaultValue = Number(this.dao.ProductModel.PremiseStateDefaultValue).toLocaleString()
      }

      let PremiseLocationDefault = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="premiseLocation")].questionDefault[0].code'
      this.dao.ProductModel.PremiseLocationDefaultValue = this.getObjUsingJpath(this.dao.DOTList, PremiseLocationDefault)[0];
      this.dao.AddProductAnotherLocation.map((c, ind) => c.premiseList = _.cloneDeep(this.changeLocationDropdown(ind)));

    }
    if (this.dao.AddAnotherInsured.length == 0) {

      let getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="additionalInsuredName")].rowIndex';
      let getMaxRowIndex = _.max(this.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.dao.AddAnotherInsured.push({
          'Name': getproductdataList?.filter(f => f.key == 'additionalInsuredName' && f.rowIndex == i)[0]?.answer
        });
      }
      this.dao.initialAddAnotherInsured = _.cloneDeep(this.dao.AddAnotherInsured)
    }
    if (this.dao.AddAnotherWaiver.length == 0) {
      let getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="glWavierOfSubrogationName")].rowIndex';
      let getMaxRowIndex = _.max(this.getObjUsingJpath(this.dao.DOTList, getMaxRow));
      for (var i = 1; i <= getMaxRowIndex; i++) {
        this.dao.AddAnotherWaiver.push({
          'Name': getproductdataList?.filter(f => f.key == 'glWavierOfSubrogationName' && f.rowIndex == i)[0]?.answer
        })
      }
      this.dao.initialAddAnotherWaiver = _.cloneDeep(this.dao.AddAnotherWaiver)
    }

    // binding garging address to product premise location, if vehicle is not loaded before from WIP. 
    if (this.dao.vehicleModel.VehiclesList.length == 0 && this.dao.IsVehicleBreadcrumpClicked == false && this.dao.IsDOTExistsInWIP == true) {
      let getGaragingAddress = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="premiseLocation")].answer';
      let getGaragingAddressIndex = this.getObjUsingJpath(this.dao.DOTList, getGaragingAddress);
      if (getGaragingAddressIndex && getGaragingAddressIndex.filter(x => x != 'businessAddressAsGLPremise' && x != 'newAddressAsPremise').length > 0) {
        let getVehicleQuery = '$..questionnaire[?(@.key =="vehiclePrefill")].questionAnswer[?(@.key =="vehicleType" && @.answer != "Unknown")]';
        let vehicleDetail = this.getObjUsingJpath(this.dao.DOTList, getVehicleQuery);
        let getTrailerQuery = '$..questionnaire[?(@.key =="vehiclePrefill")].questionAnswer[?(@.key =="vehicleType" && @.answer == "Unknown")]';
        let TrailerDetail = this.getObjUsingJpath(this.dao.DOTList, getTrailerQuery);
        await this.GetPremiseAddressFromVehicle(vehicleDetail, 'Vehicles');
        await this.GetPremiseAddressFromVehicle(TrailerDetail, 'trailer');
      }
    }

    this.constructGaragingAddress();
  }
  public async bindApplicationInfo(isWIP: boolean = false) {
    if (this.dao.IsPolicyDateNotified) {
      _.remove(this.dao.DOTList['questionnaire'], function (n) {
        return n.key == 'EvaluatePolicyHolder';
      })
    }
    if (isWIP == true) {
      await this.getDOTDetails(true, 'application');
    }
    this.dao.IsBackButtonClicked = false;
  }

  // ========== Set Product Json===============
  public async setProductData() {
    let productJSON = new Array<QuestionAnswer>();
    let tempProductDetails = _.cloneDeep(this.dao.ProductModel.productDetails);
    let temppremiseStreet = tempProductDetails.premiseStreet;
    let temppremiseCity = tempProductDetails.premiseCity;
    let temppremiseState = tempProductDetails.premiseState;
    let temppremisePostalCode = tempProductDetails.premisePostalCode;
    let temppayrollAmount = tempProductDetails.payrollAmount;
    let tempadditionalInsuredName = tempProductDetails.additionalInsuredName;
    let tempwavierOfSubrogationName = tempProductDetails.glWavierOfSubrogationName;
    let temppremiseLocation = tempProductDetails.premiseLocation;
    let tempadditionalInsuredNameForCommercialAuto = tempProductDetails.insurableInterestName;
    let tempadditionalStreetForCommercialAuto = tempProductDetails.insurableInterestStreet;
    let tempadditionalCityForCommercialAuto = tempProductDetails.insurableInterestCity;
    let tempadditionalStateForCommercialAuto = tempProductDetails.insurableInterestState;
    let tempadditionalPostalCodeForCommercialAuto = tempProductDetails.insurableInterestPostalCode;
    let tempwavierOfSubrogationNameForCommercialAuto = tempProductDetails.autoWavierOfSubrogationName;
    let tempadditionalInsuredNameForCargo = tempProductDetails.cargoInsuredName;
    let tempadditionalStreetForCargo = tempProductDetails.cargoInsuredStreet;
    let tempadditionalCityForCargo = tempProductDetails.cargoInsuredCity;
    let tempadditionalStateForCargo = tempProductDetails.cargoInsuredState;
    let tempadditionalPostalCodeForCargo = tempProductDetails.cargoInsuredPostalCode;
    let tempNamedIndividual = tempProductDetails.namedIndividual;
    // Including multiple properties in product
    if (!!this.dao.ProductModel?.productDetails?.glIncluded?.answer && this.dao.ProductModel?.productDetails?.glIncluded?.answer != 'No') {
      if (this.dao.AddProductAnotherLocation.length > 0) {
        this.dao.AddProductAnotherLocation.forEach((loc, index) => {
          if (index == 0) {
            let product = this.dao.ProductModel.productDetails;
            product.premiseStreet.answer = loc.premiseStreet;
            product.premiseCity.answer = loc.premiseCity;
            product.premiseState.answer = loc.premiseState;
            product.premisePostalCode.answer = loc.premisePostalCode;
            product.payrollAmount.answer = loc.payrollAmount;
            product.premiseLocation.answer = loc.premiseLocation;


            Object.keys(product).forEach(key => {
              if (key != 'group' && typeof (product[key]) == 'object') {
                if (key == 'premiseStreet' || key == 'premiseCity' || key == 'premiseState'
                  || key == 'premisePostalCode' || key == 'payrollAmount' || key == 'premiseLocation') {
                  product[key].rowIndex = 1;
                }
                productJSON.push(product[key]);
              }
            });

          }
          else {
            let maxindex = index + 1;
            temppremiseStreet.answer = loc.premiseStreet;
            temppremiseStreet.rowIndex = maxindex;
            temppremiseCity.answer = loc.premiseCity;
            temppremiseCity.rowIndex = maxindex;
            temppremiseState.answer = loc.premiseState;
            temppremiseState.rowIndex = maxindex;
            temppremisePostalCode.answer = loc.premisePostalCode;
            temppremisePostalCode.rowIndex = maxindex;
            temppayrollAmount.answer = loc.payrollAmount;
            temppayrollAmount.rowIndex = maxindex;
            temppremiseLocation.answer = loc.premiseLocation;
            temppremiseLocation.rowIndex = maxindex;
            // clone deep add for duplictes
            let tempArray = _.cloneDeep(productJSON);
            tempArray.push(temppremiseStreet);
            tempArray.push(temppremiseCity);
            tempArray.push(temppremiseState);
            tempArray.push(temppremisePostalCode);
            tempArray.push(temppayrollAmount);
            tempArray.push(temppremiseLocation);
            productJSON = _.cloneDeep(tempArray);
          }
        });
      }

    }
    else {
      let product = this.dao.ProductModel.productDetails;
      Object.keys(product).forEach(key => {
        productJSON.push(product[key]);
      });
    }

    if (this.dao.AddAnotherInsured.length > 0) {
      this.dao.AddAnotherInsured.forEach((ins, index) => {
        let existsInProductDetails = false;
        if (index == 0) {
          this.dao.ProductModel.productDetails.additionalInsuredName.answer = ins.Name;
          let q = productJSON.filter(x => x.key == tempadditionalInsuredName.key
            && x.group == tempadditionalInsuredName.group
            && x.rowIndex == 1
          );
          if (q.length == 1) {
            q[0].answer = ins.Name;
            existsInProductDetails = true;
          }
        }
        if (index > 0 || !existsInProductDetails) {
          let maxindex = index + 1;
          tempadditionalInsuredName.answer = ins.Name;
          tempadditionalInsuredName.rowIndex = maxindex;
          // clone deep add for duplictes
          let tempArray = _.cloneDeep(productJSON);
          tempArray.push(tempadditionalInsuredName);
          productJSON = _.cloneDeep(tempArray);
        }
      });
    }

    if (this.dao.AddAnotherWaiver.length > 0) {
      this.dao.AddAnotherWaiver.forEach((ins, index) => {
        let existsInProductDetails = false;
        if (index == 0) {
          this.dao.ProductModel.productDetails.glWavierOfSubrogationName.answer = ins.Name;
          let q = productJSON.filter(x => x.key == tempwavierOfSubrogationName.key
            && x.group == tempwavierOfSubrogationName.group
            && x.rowIndex == 1
          );
          if (q.length == 1) {
            q[0].answer = ins.Name;
            existsInProductDetails = true;
          }
        }
        if (index > 0 || !existsInProductDetails) {
          let maxindex = index + 1;
          tempwavierOfSubrogationName.answer = ins.Name;
          tempwavierOfSubrogationName.rowIndex = maxindex;
          // clone deep add for duplictes
          let tempArray = _.cloneDeep(productJSON);
          tempArray.push(tempwavierOfSubrogationName);
          productJSON = _.cloneDeep(tempArray);
        }
      });
    }
    // Commercial Auto
    if (this.dao.AddAnotherInsuredForCommercialAuto.length > 0) {
      this.dao.AddAnotherInsuredForCommercialAuto.forEach((ins, index) => {
        let existsInProductDetails = false;
        if (index == 0) {
          this.dao.ProductModel.productDetails.insurableInterestName.answer = ins.Name;
          this.dao.ProductModel.productDetails.insurableInterestStreet.answer = ins.Street;
          this.dao.ProductModel.productDetails.insurableInterestCity.answer = ins.City;
          this.dao.ProductModel.productDetails.insurableInterestState.answer = ins.State;
          this.dao.ProductModel.productDetails.insurableInterestPostalCode.answer = ins.PostalCode;
          let q1 = productJSON.filter(x => x.key == tempadditionalInsuredNameForCommercialAuto.key
            && x.group == tempadditionalInsuredNameForCommercialAuto.group
            && x.rowIndex == 1
          );
          if (q1.length >= 1) {
            q1[0].answer = ins.Name;
          }
          else {
            tempadditionalInsuredNameForCommercialAuto.answer = ins.Name;
            tempadditionalInsuredNameForCommercialAuto.rowIndex = 1;
            let tempArray = _.cloneDeep(productJSON);
            tempArray.push(tempadditionalInsuredNameForCommercialAuto);
            productJSON = _.cloneDeep(tempArray);
          }

          let q2 = productJSON.filter(x => x.key == tempadditionalStreetForCommercialAuto.key
            && x.group == tempadditionalStreetForCommercialAuto.group
            && x.rowIndex == 1
          );
          if (q2.length >= 1) {
            q2[0].answer = ins.Street;
          }
          else {
            tempadditionalStreetForCommercialAuto.answer = ins.Street;
            tempadditionalStreetForCommercialAuto.rowIndex = 1;
            let tempArray = _.cloneDeep(productJSON);
            tempArray.push(tempadditionalStreetForCommercialAuto);
            productJSON = _.cloneDeep(tempArray);
          }

          let q3 = productJSON.filter(x => x.key == tempadditionalCityForCommercialAuto.key
            && x.group == tempadditionalCityForCommercialAuto.group
            && x.rowIndex == 1
          );
          if (q3.length >= 1) {
            q3[0].answer = ins.City;
          }
          else {
            tempadditionalCityForCommercialAuto.answer = ins.City;
            tempadditionalCityForCommercialAuto.rowIndex = 1;
            let tempArray = _.cloneDeep(productJSON);
            tempArray.push(tempadditionalCityForCommercialAuto);
            productJSON = _.cloneDeep(tempArray);
          }

          let q4 = productJSON.filter(x => x.key == tempadditionalStateForCommercialAuto.key
            && x.group == tempadditionalStateForCommercialAuto.group
            && x.rowIndex == 1
          );
          if (q4.length >= 1) {
            q4[0].answer = ins.State;
          }
          else {
            tempadditionalStateForCommercialAuto.answer = ins.State;
            tempadditionalStateForCommercialAuto.rowIndex = 1;
            let tempArray = _.cloneDeep(productJSON);
            tempArray.push(tempadditionalStateForCommercialAuto);
            productJSON = _.cloneDeep(tempArray);
          }

          let q5 = productJSON.filter(x => x.key == tempadditionalPostalCodeForCommercialAuto.key
            && x.group == tempadditionalPostalCodeForCommercialAuto.group
            && x.rowIndex == 1
          );
          if (q5.length >= 1) {
            q5[0].answer = ins.PostalCode;
          }
          else {
            tempadditionalPostalCodeForCommercialAuto.answer = ins.PostalCode;
            tempadditionalPostalCodeForCommercialAuto.rowIndex = 1;
            let tempArray = _.cloneDeep(productJSON);
            tempArray.push(tempadditionalPostalCodeForCommercialAuto);
            productJSON = _.cloneDeep(tempArray);
          }
        }
        else {
          let maxindex = index + 1;
          tempadditionalInsuredNameForCommercialAuto.answer = ins.Name;
          tempadditionalInsuredNameForCommercialAuto.rowIndex = maxindex;
          tempadditionalStreetForCommercialAuto.answer = ins.Street;
          tempadditionalStreetForCommercialAuto.rowIndex = maxindex;
          tempadditionalCityForCommercialAuto.answer = ins.City;
          tempadditionalCityForCommercialAuto.rowIndex = maxindex;
          tempadditionalStateForCommercialAuto.answer = ins.State;
          tempadditionalStateForCommercialAuto.rowIndex = maxindex;
          tempadditionalPostalCodeForCommercialAuto.answer = ins.PostalCode;
          tempadditionalPostalCodeForCommercialAuto.rowIndex = maxindex;
          // clone deep add for duplictes
          let tempArray = _.cloneDeep(productJSON);
          tempArray.push(tempadditionalInsuredNameForCommercialAuto);
          tempArray.push(tempadditionalStreetForCommercialAuto);
          tempArray.push(tempadditionalCityForCommercialAuto);
          tempArray.push(tempadditionalStateForCommercialAuto);
          tempArray.push(tempadditionalPostalCodeForCommercialAuto);
          productJSON = _.cloneDeep(tempArray);
        }
      });
    }

    if (this.dao.AddAnotherWaiverForCommercialAuto.length > 0) {
      this.dao.AddAnotherWaiverForCommercialAuto.forEach((ins, index) => {
        let existsInProductDetails = false;
        if (index == 0) {
          this.dao.ProductModel.productDetails.autoWavierOfSubrogationName.answer = ins.Name;
          let q = productJSON.filter(x => x.key == tempwavierOfSubrogationNameForCommercialAuto.key
            && x.group == tempwavierOfSubrogationNameForCommercialAuto.group
            && x.rowIndex == 1
          );
          if (q.length >= 1) {
            q[0].answer = ins.Name;
            existsInProductDetails = true;
          }
        }
        if (index > 0 || !existsInProductDetails) {
          let maxindex = index + 1;
          tempwavierOfSubrogationNameForCommercialAuto.answer = ins.Name;
          tempwavierOfSubrogationNameForCommercialAuto.rowIndex = maxindex;
          // clone deep add for duplictes
          let tempArray = _.cloneDeep(productJSON);
          tempArray.push(tempwavierOfSubrogationNameForCommercialAuto);
          productJSON = _.cloneDeep(tempArray);
        }
      });
    }

    if (this.dao.ProductModel.productDetails.cargoIncluded.answer == 'Yes') {
      if (this.dao.AddAnotherInsuredForCargo.length > 0) {
        this.dao.AddAnotherInsuredForCargo.forEach((ins, index) => {
          let existsInProductDetails = false;
          if (index == 0) {
            this.dao.ProductModel.productDetails.cargoInsuredName.answer = ins.Name;
            this.dao.ProductModel.productDetails.cargoInsuredStreet.answer = ins.Street;
            this.dao.ProductModel.productDetails.cargoInsuredCity.answer = ins.City;
            this.dao.ProductModel.productDetails.cargoInsuredState.answer = ins.State;
            this.dao.ProductModel.productDetails.cargoInsuredPostalCode.answer = ins.PostalCode;
            let q1 = productJSON.filter(x => x.key == tempadditionalInsuredNameForCargo.key
              && x.group == tempadditionalInsuredNameForCargo.group
              && x.rowIndex == 1
            );
            if (q1.length >= 1) {
              q1[0].answer = ins.Name;
            }
            else {
              tempadditionalInsuredNameForCargo.answer = ins.Name;
              tempadditionalInsuredNameForCargo.rowIndex = 1;
              let tempArray = _.cloneDeep(productJSON);
              tempArray.push(tempadditionalInsuredNameForCargo);
              productJSON = _.cloneDeep(tempArray);
            }

            let q2 = productJSON.filter(x => x.key == tempadditionalStreetForCargo.key
              && x.group == tempadditionalStreetForCargo.group
              && x.rowIndex == 1
            );
            if (q2.length >= 1) {
              q2[0].answer = ins.Street;
            }
            else {
              tempadditionalStreetForCargo.answer = ins.Street;
              tempadditionalStreetForCargo.rowIndex = 1;
              let tempArray = _.cloneDeep(productJSON);
              tempArray.push(tempadditionalStreetForCargo);
              productJSON = _.cloneDeep(tempArray);
            }

            let q3 = productJSON.filter(x => x.key == tempadditionalCityForCargo.key
              && x.group == tempadditionalCityForCargo.group
              && x.rowIndex == 1
            );
            if (q3.length >= 1) {
              q3[0].answer = ins.City;
            }
            else {
              tempadditionalCityForCargo.answer = ins.City;
              tempadditionalCityForCargo.rowIndex = 1;
              let tempArray = _.cloneDeep(productJSON);
              tempArray.push(tempadditionalCityForCargo);
              productJSON = _.cloneDeep(tempArray);
            }

            let q4 = productJSON.filter(x => x.key == tempadditionalStateForCargo.key
              && x.group == tempadditionalStateForCargo.group
              && x.rowIndex == 1
            );
            if (q4.length >= 1) {
              q4[0].answer = ins.State;
            }
            else {
              tempadditionalStateForCargo.answer = ins.State;
              tempadditionalStateForCargo.rowIndex = 1;
              let tempArray = _.cloneDeep(productJSON);
              tempArray.push(tempadditionalStateForCargo);
              productJSON = _.cloneDeep(tempArray);
            }

            let q5 = productJSON.filter(x => x.key == tempadditionalPostalCodeForCargo.key
              && x.group == tempadditionalPostalCodeForCargo.group
              && x.rowIndex == 1
            );
            if (q5.length >= 1) {
              q5[0].answer = ins.PostalCode;
            }
            else {
              tempadditionalPostalCodeForCargo.answer = ins.PostalCode;
              tempadditionalPostalCodeForCargo.rowIndex = 1;
              let tempArray = _.cloneDeep(productJSON);
              tempArray.push(tempadditionalPostalCodeForCargo);
              productJSON = _.cloneDeep(tempArray);
            }
          }
          else {
            let maxindex = index + 1;
            tempadditionalInsuredNameForCargo.answer = ins.Name;
            tempadditionalInsuredNameForCargo.rowIndex = maxindex;
            tempadditionalStreetForCargo.answer = ins.Street;
            tempadditionalStreetForCargo.rowIndex = maxindex;
            tempadditionalCityForCargo.answer = ins.City;
            tempadditionalCityForCargo.rowIndex = maxindex;
            tempadditionalStateForCargo.answer = ins.State;
            tempadditionalStateForCargo.rowIndex = maxindex;
            tempadditionalPostalCodeForCargo.answer = ins.PostalCode;
            tempadditionalPostalCodeForCargo.rowIndex = maxindex;
            // clone deep add for duplictes
            let tempArray = _.cloneDeep(productJSON);
            tempArray.push(tempadditionalInsuredNameForCargo);
            tempArray.push(tempadditionalStreetForCargo);
            tempArray.push(tempadditionalCityForCargo);
            tempArray.push(tempadditionalStateForCargo);
            tempArray.push(tempadditionalPostalCodeForCargo);
            productJSON = _.cloneDeep(tempArray);
          }
        });
      }
    }

    if (this.dao.AddAnotherNamedIndividual.length > 0) {
      this.dao.AddAnotherNamedIndividual.forEach((ins, index) => {
        let existsInProductDetails = false;
        if (index == 0) {
          this.dao.ProductModel.productDetails.namedIndividual.answer = ins.Name;
          let q = productJSON.filter(x => x.key == tempNamedIndividual.key
            && x.group == tempNamedIndividual.group
            && x.rowIndex == 1
          );
          if (q.length >= 1) {
            q[0].answer = ins.Name;
            existsInProductDetails = true;
          }
        }
        if (index > 0 || !existsInProductDetails) {
          let maxindex = index + 1;
          tempNamedIndividual.answer = ins.Name;
          tempNamedIndividual.rowIndex = maxindex;
          // clone deep add for duplictes
          let tempArray = _.cloneDeep(productJSON);
          tempArray.push(tempNamedIndividual);
          productJSON = _.cloneDeep(tempArray);
        }
      });
    }

    const productQuestionnaire = this.dao.DOTList.questionnaire.find(q => q.key === 'product') as Questionnaire;

    this.mapQuestionAnswerByKey('uimBiPrd', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('uimBi', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('uimBiPrdDed', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('uimPrd', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('uimPrdDed', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('tortLimitSelect', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('tortLimits', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('Q_nfBrd', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('nfBrd', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('nfBrdDed', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('sgEmployeresliab', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('sgPayroll', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('sgBIAccidentEach', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('sgBIDiseaseLimit', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('sgBIDiseaseEach', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('atFaultLiab', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('umBiDed', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('umBiLimit', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('umPdLimit', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('umPdDed', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('umPdPrd', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('umPdPrdDed', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('insured_wc', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('lossOfIncomeBenefits', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('Q_addedPIP', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('addedPIP', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('addedPIPDed', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('hiredAutoLiability', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('annualCostOfHire', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('hiredAutoUimBi', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('umReject', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('umStacked', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('liabilityLimit', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('mpLimit', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('medExpenseBenefits', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('incomeLoss', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('extendedPip', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('excludeCoverageForWorkLoss', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('pipWorkLoss', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('hiredAutoUmBi', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('umEconomicLoss', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('fireSurcharge', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('nfRateBasis', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('isExcludeUimBi', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('isExcludeUimBiPrd', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('trailerDamageLimit', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('trailerCompDeductible', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('trailerCollDeductible', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('trailerRadius', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('trailerZoneFrom', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('trailerZoneTo', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('nonOwnedTrailerPerDay', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('nonOwnedTrailerPerYear', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('trailerShipments', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('pipDedAppliesTo', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('propertyLimit', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('cargoShipperLimit', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('nonOwnedAutoLiability', productQuestionnaire, productJSON);
    this.mapQuestionAnswerByKey('nonOwnedAutoLiabEmployeesCount', productQuestionnaire, productJSON);

    productQuestionnaire.questionAnswer = _.cloneDeep(productJSON);
  }

  private mapQuestionAnswerByKey(key: string, questionnaire: Questionnaire, questionAnswers: Array<QuestionAnswer>): void {
    const questionAnswer = questionnaire?.questionAnswer.find(qa => qa.key === key);

    if (questionAnswer && questionAnswers) {
      questionAnswers.push(questionAnswer);
    }
  }
  // ================ show vehicle action btn==
  public isShowActinBtn(type) {
    if (type == 'vehicle') {
      return (this.dao?.vehicleModel?.VehiclesList?.length == 0 || (this.dao?.vehicleModel?.vehiclePage == 3 && ((!this.dao?.isTrailer && this.dao?.vehicleModel?.VehiclesList?.length - 1 == this.dao?.vehicleModel?.vehicleIndex) || (this.dao?.isTrailer && this.dao.vehicleModel.VehiclesList.filter(v => v.isReviewed == false).length == 0 && this.dao?.vehicleModel?.trailerList?.length - 1 == this.dao.vehicleModel.trailerIndex))));
    }
    else if (type == 'trailer') {
      return (this.dao?.vehicleModel?.vehiclePage == 3 && (this.dao?.vehicleModel?.trailerList?.filter(c => c?.isReviewed == false)?.length == 0
        || (this.dao?.isTrailer && this.dao?.vehicleModel?.trailerList?.length - 1 == this.dao?.vehicleModel?.trailerIndex))
        && ((!this.dao?.isTrailer && this.dao?.vehicleModel?.VehiclesList?.length - 1 == this.dao?.vehicleModel?.vehicleIndex) || (this.dao?.isTrailer && this.dao?.vehicleModel?.trailerList?.length - 1 == this.dao.vehicleModel.trailerIndex)))
    }
    else if (type == 'finalNext') {
      return (!this.dao?.isTrailer && (this.dao?.vehicleModel?.VehiclesList?.length - 1 != this.dao.vehicleModel.vehicleIndex || this.dao?.vehicleModel?.trailerList?.filter(v => v.isReviewed == false).length > 0)) || (this.dao?.isTrailer && this.dao?.vehicleModel?.trailerList?.length - 1 != this.dao.vehicleModel.trailerIndex)
    }
    else if (type == 'finalSave') {
      let isAllReviewed = true;
      let notReviewedData = [];
      let requiredDatas = ['vehicleYear', 'vehicleMake', 'vehicleModel', 'vehicleVin', 'vehicleGarageStreetAddress', 'vehicleGarageCity', 'vehicleGarageState', 'vehicleGaragePostalCode', 'vehicleSize', 'trailerSize', 'vehicleBodyType', 'vehicleTotalMilesUpto50', 'vehicleTotalMilesUpto200']
      notReviewedData = this.dao?.vehicleModel?.VehiclesList.filter((v, i) => v.isReviewed == false);
      notReviewedData = notReviewedData.concat(this.dao?.vehicleModel?.trailerList.filter((v, i) => v.isReviewed == false))
      if (notReviewedData?.length > 0) {
        notReviewedData.forEach(x => {
          x.isReviewed = true;
          let isVehicle = x['vehicleType'].answer == '';
          requiredDatas.forEach(v => {
            if (isVehicle && v == 'trailerSize') {
              v = 'vehicleSize';
            }
            if (!isVehicle && v == 'vehicleSize') {
              v = 'trailerSize';
            }
            if (x[v] && (x[v].answer == '' || x[v].answer == '0')) {              
              x.isReviewed = false;
              if ((v =='vehicleModel' || v =='vehicleBodyType') && x[v].group.startsWith('TRA-'))
              {
                x.isReviewed = true; // trailer model and body type is not required
              }
            }
            x.vehicleStatedAmount.answer = x?.vehicleStatedAmount?.answer == '' ? '0' : x?.vehicleStatedAmount?.answer;
          });
        });
      }
      if (this.dao?.vehicleModel?.VehiclesList && this.dao?.vehicleModel?.VehiclesList?.length > 0) {
        isAllReviewed = this.dao?.vehicleModel?.VehiclesList.filter((v, i) => v.isReviewed == false && i != this.dao.vehicleModel.VehiclesList.length - 1).length == 0;
        if (isAllReviewed && this.dao?.vehicleModel?.VehiclesList.filter(v => v.isReviewed == false).length > 0) {
          isAllReviewed = !this.dao.isTrailer && this.dao.vehicleModel.vehicleIndex == this.dao.vehicleModel.VehiclesList.length - 1 && this.dao.vehicleModel.vehiclePage == 3 && this.dao?.vehicleModel?.trailerList?.filter(v => v.isReviewed == false).length == 0;
        }
      }
      if (isAllReviewed && this.dao?.vehicleModel?.trailerList && this.dao?.vehicleModel?.trailerList?.length > 0) {
        isAllReviewed = this.dao?.vehicleModel?.trailerList.filter((v, i) => v.isReviewed == false && i != this.dao.vehicleModel.trailerList.length - 1).length == 0;
        if (isAllReviewed && this.dao?.vehicleModel?.trailerList.filter(v => v.isReviewed == false).length > 0) {
          isAllReviewed = this.dao.isTrailer && this.dao.vehicleModel.trailerIndex == this.dao.vehicleModel.trailerList.length - 1 && this.dao.vehicleModel.vehiclePage == 3;
        }
      }
      // showing and hiding saveAs button and save and continue button
      if (isAllReviewed == false) {
        this.dao.IsShowVehicleDraftBtn = true;
      } else {
        this.dao.IsShowVehicleDraftBtn = false;
      }
      return isAllReviewed
    }
    return true;
  }
  // ====== Set Drodown options for view PDF =======
  public async setDropdownOptions() {
    let dropdownsList = ['vehicleSize', 'trailerSize', 'vehicleBodyType', 'vehicleSecondaryClass', 'vehicleToZone', 'vehicleComp', 'vehicleColl', 'addInsuredInterestType', 'addInsuredState']
    dropdownsList.forEach(key => {
      let OptionsList = this.getObjUsingJpath(this.dao.DOTList, "$..questionnaire[?(@.key =='vehiclePrefill')].questionAnswer[?(@.key == '" + key + "')]");
      if (OptionsList && OptionsList?.length > 0) {
        OptionsList.forEach(zone => {
          let questionOpt = _.cloneDeep(this.getObjUsingJpath(this.dao.DOTList, "$..questionnaire[?(@.key =='vehicle')].questionAnswer[?(@.key == '" + key + "')]"));
          if (questionOpt && questionOpt?.length > 0) {
            zone.questionOptions = questionOpt[0].questionOptions;
            zone.type = "Select";
          }
        });
      }
    });
    let payrollAmountList = this.getObjUsingJpath(this.dao.DOTList, "$..questionnaire[?(@.key =='product')].questionAnswer[?(@.key == 'payrollAmount')]");
    if (payrollAmountList && payrollAmountList.length > 0) {
      payrollAmountList.forEach(c => {
        if (!isNaN(Number(c.answer))) {
          c.answer = Number(c.answer).toLocaleString()
        }
      });
    }
  }
  // ===== Get Date ===========
  public getCorrectDate(date) {
    let correctDate = date;
    if (date && date != '' && typeof (date) == 'string' && date.split('-').length > 0) {
      let year = Number(date.split('-')[0]);
      let month = Number(date.split('-')[1]);
      let day = Number(date.split('-')[2]);
      if (year != undefined && !isNaN(year) && month != undefined && !isNaN(month) && day != undefined && !isNaN(day)) {
        correctDate = new Date(year, month - 1, day);
      }
    }
    return correctDate;
  }
  // ====== Set initial Data=====
  public setInitialData() {
  
    this.dao.initialProductModel = _.cloneDeep(this.dao.ProductModel);
    this.dao.initialProductQuestionnaireAnswers = _.cloneDeep(this.dao.DOTList?.questionnaire?.find(q => q.key === 'product'));
    this.dao.initialAddProductAnotherLocation = _.cloneDeep(this.dao.AddProductAnotherLocation);
    this.dao.initialAddAnotherInsured = _.cloneDeep(this.dao.AddAnotherInsured);
    this.dao.initialAddAnotherWaiver = _.cloneDeep(this.dao.AddAnotherWaiver);
    this.dao.initialAddAnotherInsuredForCommercialAuto = _.cloneDeep(this.dao.AddAnotherInsuredForCommercialAuto);
    this.dao.initialAddAnotherWaiverForCommercialAuto = _.cloneDeep(this.dao.AddAnotherWaiverForCommercialAuto);
    this.dao.InitialAddAnotherInsuredForCargo = _.cloneDeep(this.dao.AddAnotherInsuredForCargo);
    this.dao.initialAddAnotherNamedIndividual = _.cloneDeep(this.dao.AddAnotherNamedIndividual);
  }
  // consruct Garaging address dropdown ===
  public constructGaragingAddress() {
    let garagingAddressList = _.cloneDeep(this.dao?.ProductModel?.productDetails?.premiseLocation?.questionOptions);
    if (garagingAddressList && garagingAddressList.length > 0) {
      if (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length > 0) {
        this.dao.vehicleModel.VehiclesList.forEach(vehicle => {
          let tempGaragingAddress: any = {};
          tempGaragingAddress.label = vehicle.vehicleGarageStreetAddress.answer + ' ' + vehicle.vehicleGarageCity.answer + ' ' + vehicle.vehicleGarageState.answer + ' ' + vehicle.vehicleGaragePostalCode.answer;
          tempGaragingAddress.code = vehicle.vehicleYear.group;
          tempGaragingAddress.value = vehicle.vehicleYear.group;
          if (vehicle.vehicleIsCorrectGaragingAddress.answer == 'No' && garagingAddressList.filter(c => c.label == tempGaragingAddress.label).length == 0) {
            garagingAddressList.push(tempGaragingAddress);
          }
        })
      }
      if (this.dao.vehicleModel.trailerList && this.dao.vehicleModel.trailerList.length > 0) {
        this.dao.vehicleModel.trailerList.forEach(vehicle => {
          let tempGaragingAddress: any = {};
          tempGaragingAddress.label = vehicle.vehicleGarageStreetAddress.answer + ' ' + vehicle.vehicleGarageCity.answer + ' ' + vehicle.vehicleGarageState.answer + ' ' + vehicle.vehicleGaragePostalCode.answer;
          tempGaragingAddress.code = vehicle.vehicleYear.group;
          tempGaragingAddress.value = vehicle.vehicleYear.group;
          if (vehicle.vehicleIsCorrectGaragingAddress.answer == 'No' && garagingAddressList.filter(c => c.label == tempGaragingAddress.label).length == 0) {
            garagingAddressList.push(tempGaragingAddress);
          }
        })
      }
      this.dao.ProductModel.productDetails.premiseLocation.questionOptions = _.cloneDeep(garagingAddressList);
      if (this.dao.AddProductAnotherLocation && this.dao.AddProductAnotherLocation.length > 0) {
        this.dao.AddProductAnotherLocation.map((c, ind) => c.premiseList = _.cloneDeep(this.changeLocationDropdown(ind)));
      }
    }
  }
  // get Screen Order
  public getPageOrder(page) {
    let getTemplateOrder = (this.saveDatas.filter(s => s.template == page) && this.saveDatas.filter(s => s.template == page).length > 0) ? this.saveDatas.filter(s => s.template == page)[0].order : 0;
    return getTemplateOrder;
  }

  // Location dropdown option build
  public changeLocationDropdown(index) {
    let optionList = _.cloneDeep(this.dao?.ProductModel?.productDetails?.premiseLocation?.questionOptions);
    if (this.dao.AddProductAnotherLocation && this.dao.AddProductAnotherLocation.length > 0) {
      this.dao.AddProductAnotherLocation.forEach((loc, i) => {
        if (index != i) {
          _.remove(optionList, function (n) {
            return n.code == loc.premiseLocation && n.code != 'CLICK' && n.code != 'newAddressAsPremise';
          })
        }
      });
    }
    return optionList;
  }
  // check form valid
  formValidation(form) {
    let formData = _.cloneDeep(form);
    if (formData && formData._directives && formData._directives.length > 0) {
      formData._directives.forEach(f => {
        if (f && f.value && typeof (f.value) == "string" && f.value.trim() == '' && f.validator("required") && f.validator("required").required) {
          f.reset();
        }
      })
    }
    return formData;
  }
  validateRequiredFieldAnsweredAllForms(forms: NgForm[]): boolean {
    let i = 0;
    for (i = 0; i < forms.length; i++) {
      if (!this.validateRequiredFieldAnswered(forms[i]))
        return false;
    }
    return true;
  }

  validateRequiredFieldAnswered(form): boolean {
    let formData = _.cloneDeep(form);
    let returnval: boolean = true;
    if (formData && formData._directives && formData._directives.length > 0) {
      for (const f of formData._directives) {
        if (typeof (f.value) == "string" && f.value.trim() == ''
          && f.control.validator != null && f.validator("required")
          && f.validator("required").required) {
          f.reset();
          returnval = false;
        }
      }
    }
    return returnval;
  }

  public UpdateFiledsValuesAndValidate(form): boolean {
    if (form == undefined)
      return true;
    if (form && form._directives && form._directives.length > 0) {
      for (const f of form._directives) {
        f.control?.updateValueAndValidity();
      }
    }
    return form?.valid;
  }

  // Restrict the Auto fill
  public restrictAutoFill() {
    if (Object.keys($('input'))) {
      Object.keys($('input')).map(con => {
        if ($('input')[con].name == 'vehicleStatedAmount') {
          $('input')[con].setAttribute("autocomplete", "none")
          $('input')[con].setAttribute("autocomplete", "off")
        }
        if (con != 'length' && con != 'prevObject' && $('input')[con].type == 'text' && $('input')[con].id.includes('calendarIcon') != true
          && $('input')[con].id != 'txtCityID') { // it's should change type property only for text controls not calendar and file upload
          $('input')[con].setAttribute("autocomplete", "off")
          $('input')[con].setAttribute("type", "search")
        }

      });
    }
  }
  public async menuClick(menuContext) {
    this.dao.IsSavedFromMenu = false;
    let jsonPathRef = require('jsonpath');
    let dotNumber = this.dao.DOTList.dotQsnr.findQuestion("accountDot")?.answer;
    let CurrentSubmissionStatus = jsonPathRef.query(this.dao, '$..SubmissionList[?(@.dotNumber =="' + dotNumber + '")].status')[0];
    // hasDataChange will be deleted and the code will only check menuContext.currentPageDataChanged 
    if (this.dao.PolicyTemplate == "product") {
      menuContext.currentPageDataChanged = this.dataChangeService.HasCurrentPolicyTemplateDataChanged();
    }
    let page: string = menuContext.page;

    if (menuContext.currentPageIsValid && !menuContext.currentPageDataChanged){
      if (page == 'product')
        {
          this.bindProduct();
        } 
      this.dao.PolicyTemplate = page;
      this.dao.IsBackButtonClicked = false;
      this.setActiveMenu(page);
      window.scrollTo(0, 300);
      return false;
    }
    else if (menuContext.currentPageDataChanged)
    {
        this.dao.clickedPage = page;
        this.dao.ClickedPageFromMenuPage = page;
        this.dao.isClickFromMenu = true;
        let currentPageOrder = this.getPageOrder(this.dao.PolicyTemplate);
        let clickedPage = this.getPageOrder(page);
        this.dao.IsSavedFromMenu = currentPageOrder >= clickedPage;
        if (this.dao.PolicyTemplate != "AccountInfo")
          this.dao.NotAllowedToSubmit = false;

        let submitButton = document.getElementById("btnsaveContinueID") as HTMLElement;
        if (!!submitButton) {
          submitButton.click();
          setTimeout(() => {
            this.validateSubmittedData();
          }, 500);
        } else {
          if (this.dao.PolicyTemplate == 'vehicles') {
            this.throwVehicleValidationPopup();
          }
        }
        return false;
    }    
    else{
      this.dao.NotAllowedToSubmit = true;
      this.dao.clickedPage = page;
      this.dao.ClickedPageFromMenuPage = page;
      this.dao.isClickFromMenu = true;
      let currentPageOrder = this.getPageOrder(this.dao.PolicyTemplate);
      let clickedPage = this.getPageOrder(page);
      this.dao.IsSavedFromMenu = currentPageOrder >= clickedPage;
      this.validateSubmittedData();
    } 
  }
  public async validateSubmittedData() {
    if (this.dao.NotAllowedToSubmit) {
      this.dao.NotAllowedToSubmit = false;
      this.dao.IsSavedFromMenu = false;
      this.dao.isEmptyListFromMenu = true;
      this.dao.clickedPage = this.dao.ClickedPageFromMenuPage;
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'madeChange', sticky: true, severity: 'warn', summary: "If you go back, any data entered will be lost.  Are you sure you would like to go back?" });
    }
    else {
      if (this.dao.IsSavedFromMenu) {
        this.dao.IsSavedFromMenu = false;
        this.setActiveMenu(this.dao.ClickedPageFromMenuPage);
        this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;       
      }
    }
  }

  public openStreamPDF(streamString) {
    var arrrayBuffer = this.base64ToArrayBuffer(streamString);
    var blob = new Blob([arrrayBuffer], { type: "application/pdf" });
    var link = window.URL.createObjectURL(blob);
    window.open(link, '', 'height=650,width=840');
  }
  public base64ToArrayBuffer(streamString) {
    var binaryString = window.atob(streamString);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  // To Add Space after the number in Address field.
  public ValidateAddress(event, value) {
    let IsvalueHasnum = /^\d+$/.test(value.trim());
    if (value && value != '' && (!this.hasWhiteSpace(value) || value.trim().length == 1) && IsvalueHasnum) {
      let inputChar = String.fromCharCode(event.charCode);
      // To check the string has only numbers
      let isnum = /^\d+$/.test(value + inputChar);
      if (!isnum) {
        if (inputChar != ' ') {
          value = value + ' ';
          return value;
        }
      }
    }
    return null;
  }

  // To check the string has empty space, if yes it returns true else false.
  public hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
  }
  // bind updated premise address
  async SetPremiseAddress(DOTList) {
    let getproductdata = '$..questionnaire[?(@.key =="product")].questionAnswer';
    let getproductdataList = _.max(this.getObjUsingJpath(DOTList, getproductdata));
    let businessAddIndex = getproductdataList.filter(c => c.key == 'premiseLocation' && c.answer == 'businessAddressAsGLPremise')[0].rowIndex;
    let tempbusiness = this.dao.AddProductAnotherLocation.filter(c => c.premiseLocation == 'businessAddressAsGLPremise');
    if (tempbusiness && tempbusiness.length > 0 && businessAddIndex) {
      tempbusiness[0].premiseStreet = getproductdataList?.filter(f => f.key == 'premiseStreet' && f.rowIndex == businessAddIndex)[0]?.answer;
      tempbusiness[0].premiseCity = getproductdataList?.filter(f => f.key == 'premiseCity' && f.rowIndex == businessAddIndex)[0]?.answer;
      tempbusiness[0].premiseState = getproductdataList?.filter(f => f.key == 'premiseState' && f.rowIndex == businessAddIndex)[0]?.answer;
      tempbusiness[0].premisePostalCode = getproductdataList?.filter(f => f.key == 'premisePostalCode' && f.rowIndex == businessAddIndex)[0]?.answer;
      tempbusiness[0].premiseList = getproductdataList?.filter(f => f.key == 'premiseLocation' && f.rowIndex == businessAddIndex)[0]?.questionOptions;
      this.dao.ProductModel.productDetails.premiseLocation.questionOptions = tempbusiness[0].premiseList;
    }

  }
  // bind vehicle garaging address
  async bindVehicleGaragingAddress() {
    let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
    let accountAddress = new PolicyHolderValueParser(accountInfoQsnr).accountAddress;
    this.dao?.vehicleModel?.VehiclesList?.forEach((vehicle, index) => {
      let garagingAddressChecked = vehicle.vehicleIsCorrectGaragingAddress.answer;
      Object.keys(vehicle).forEach(tempKey => {
        if (typeof (vehicle[tempKey]) == 'object') {
          vehicle[tempKey].group = ('VEH-' + (index + 1));
          if (tempKey == "vehicleGaragingAddress") {
            vehicle[tempKey].answer = accountAddress.toString();
          }
          else if (tempKey == 'vehicleGarageStreetAddress' && garagingAddressChecked != 'No') {
            vehicle[tempKey].answer = accountAddress.street;
          }
          else if (tempKey == 'vehicleGarageCity' && garagingAddressChecked != 'No') {
            vehicle[tempKey].answer = accountAddress.city;
          }
          else if (tempKey == 'vehicleGarageState' && garagingAddressChecked != 'No') {
            vehicle[tempKey].answer = accountAddress.state;
          }
          else if (tempKey == 'vehicleGaragePostalCode' && garagingAddressChecked != 'No') {
            vehicle[tempKey].answer = accountAddress.postalCode;
          }
        }
      });
    });

    this.dao?.vehicleModel?.trailerList?.forEach((trailer, index) => {
      let garagingAddressChecked = trailer.vehicleIsCorrectGaragingAddress.answer;
      Object.keys(trailer).forEach(tempKey => {
        if (typeof (trailer[tempKey]) == 'object') {
          trailer[tempKey].group = ('TRA-' + (index + 1));
          if (tempKey == "vehicleGaragingAddress") {
            trailer[tempKey].answer = accountAddress.toString();
          }
          else if (tempKey == 'vehicleGarageStreetAddress' && garagingAddressChecked != 'No') {
            trailer[tempKey].answer = accountAddress.street;
          }
          else if (tempKey == 'vehicleGarageCity' && garagingAddressChecked != 'No') {
            trailer[tempKey].answer = accountAddress.city;
          }
          else if (tempKey == 'vehicleGarageState' && garagingAddressChecked != 'No') {
            trailer[tempKey].answer = accountAddress.state;
          }
          else if (tempKey == 'vehicleGaragePostalCode' && garagingAddressChecked != 'No') {
            trailer[tempKey].answer = accountAddress.postalCode;
          }
        }
      });
    });

    if (this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == null || this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == undefined) {
      this.dao.editAllUnits.vehicleGarageStreetAddress.answer = accountAddress.street;
      this.dao.editAllUnits.vehicleGarageState.answer = accountAddress.state;
      this.dao.editAllUnits.vehicleGarageCity.answer = accountAddress.city;
      this.dao.editAllUnits.vehicleGaragePostalCode.answer = accountAddress.postalCode;
    }
  }
  public async breadcrumpClick(page = "") {
    if (page == 'product') {
      await this.bindProduct(true);
    }
    this.dao.PolicyTemplate = this.dao.ClickedPageFromMenuPage;
    await this.setActiveMenu(page);
    return true;
  }
  // get Screen Order
  public getPageNamebyOrder(orderNumber) {
    let getTemplateOrder = (this.saveDatas.filter(s => s.order == orderNumber) && this.saveDatas.filter(s => s.order == orderNumber).length > 0) ? this.saveDatas.filter(s => s.order == orderNumber)[0].template : '';
    return getTemplateOrder;
  }

  public async GetPremiseAddressFromVehicle(UnitDetail, type) {
    if (UnitDetail) {
      await Promise.all(UnitDetail.map(async (v) => {
        let vehicleData = this.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key =="vehiclePrefill")].questionAnswer[?(@.group =="' + v.group + '")]');
        let TempVehicle = _.cloneDeep(this.dao.vehicleModel.vehicleDefault);
        let TempGroup = '';
        let zoneDataIndex = _.max(this.getObjUsingJpath(vehicleData, '$..[?(@.key =="vehicleToZone" || @.key =="vehicleApplicableZonePercent")].rowIndex'));
        let zoneDatas = this.getObjUsingJpath(vehicleData, '$..[?(@.key =="vehicleToZone" || @.key =="vehicleApplicableZonePercent")]');
        let interestIndexAll = this.getObjUsingJpath(vehicleData, '$..[?(@.key =="addInsuredInterestType" || @.key =="addInsuredName" || @.key =="addInsuredStreetAddress" || @.key =="addInsuredCity" || @.key =="addInsuredState" || @.key =="addInsuredPostalCode" || @.key =="addInsuredLoanNumber")].rowIndex');
        var interestIndex = [...new Set(interestIndexAll)]?.sort();
        let interestDatas = this.getObjUsingJpath(vehicleData, '$..[?(@.key =="addInsuredInterestType" || @.key =="addInsuredName" || @.key =="addInsuredStreetAddress" || @.key =="addInsuredCity" || @.key =="addInsuredState" || @.key =="addInsuredPostalCode" || @.key =="addInsuredLoanNumber")]');
        vehicleData.forEach(c => {
          TempVehicle[c.key] = c;
          TempGroup = c.group;
          if ((TempVehicle[c.key].answer == undefined || TempVehicle[c.key].answer == '') && TempVehicle[c.key].questionDefault && TempVehicle[c.key].questionDefault.length > 0) {
            TempVehicle[c.key].answer = TempVehicle[c.key].questionDefault[0].code;
          }
        });
        Object.keys(TempVehicle).forEach(tempKey => {
          if (typeof (TempVehicle[tempKey]) == 'object') {
            TempVehicle[tempKey].group = TempGroup;
          }
        });

        TempVehicle.interestList = [];
        if (interestIndex !== undefined) {
          interestIndex.forEach((j, i) => {
            if (interestDatas && interestDatas?.length > 0 && interestDatas.filter(z => z.rowIndex == j).length > 0) {
              TempVehicle.interestList.push({
                'Type': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredInterestType')[0].answer,
                'Name': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredName')[0].answer,
                'streetAddress': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredStreetAddress')[0].answer,
                'city': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredCity')[0].answer,
                'state': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredState')[0].answer,
                'zip': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredPostalCode')[0].answer,
                'loanNo': interestDatas.filter(z => z.rowIndex == j && z.key == 'addInsuredLoanNumber')[0].answer
              });
            }
          });
        }
        else {
          TempVehicle.interestList.push({ 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' });
        }
        TempVehicle.vehicleGaragingAddress = TempVehicle.vehicleGaragingAddress != '' ? TempVehicle.vehicleGaragingAddress : this.createNewGaragingAddressFromAccountAddress(TempVehicle?.vehicleGaragingAddress?.group);
        TempVehicle.isReviewed = (this.dao.finalSubmitPage == 'vehicles' || this.dao.finalSubmitPage == 'driver' || this.dao.finalSubmitPage == 'product' || this.dao.finalSubmitPage == 'application') ? true : false;
        TempVehicle[(type != 'trailer' ? 'vehicle' : 'trailer') + 'Size'].helpText = this.dao.vehicleModel.vehicleDefault[(type != 'trailer' ? 'vehicle' : 'trailer') + 'Size'].questionOptions.filter(c => c.value == TempVehicle[(type != 'trailer' ? 'vehicle' : 'trailer') + 'Size'].answer)[0]?.helpText;
        this.dao.vehicleModel[type + 'List'].push(TempVehicle);
      }));
    }
  }
  public async SetAddressInVehicleAndProduct() {
    if (this.dao.IsProductBreadcrumpClicked == true) {
      if (this.dao.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise').length > 0) {
        let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
        let accountAddress = new PolicyHolderValueParser(accountInfoQsnr).accountAddress;
        this.dao.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise')[0].premiseStreet = accountAddress.street;
        this.dao.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise')[0].premiseCity = accountAddress.city;
        this.dao.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise')[0].premiseState = accountAddress.state;
        this.dao.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise')[0].premisePostalCode = accountAddress.postalCode;
        this.dao.AddProductAnotherLocation.filter(x => x.premiseLocation == 'businessAddressAsGLPremise')[0].premiseList.filter(l => l.code == 'businessAddressAsGLPremise')[0].label = accountAddress.toString();

        this.dao.ProductModel.productDetails.premiseLocation.questionOptions =
          this.dao.DOTList.questionnaire?.filter(x => x.key == 'product')[0].questionAnswer.filter(p => p.key == 'premiseLocation')[0].questionOptions;
      }
    }
  }
  public async ChangeVehicleGaragingAddress() {
    if (this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer != '') {
      let accountInfoQsnr = (this.dao.DOTList as ResponseContainer).accountInfoQsnr;
      let accountAddress = new PolicyHolderValueParser(accountInfoQsnr).accountAddress;
      this.dao.vehicleModel.vehicleItem.vehicleGarageStreetAddress.answer = accountAddress.street;
      this.dao.vehicleModel.vehicleItem.vehicleGarageCity.answer = accountAddress.city;
      this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer = accountAddress.state;
      this.dao.vehicleModel.vehicleItem.vehicleGaragePostalCode.answer = accountAddress.postalCode;
    }
  }
  public async GetAuthToken() {

    let AuthTokenDetails: any = await this.commonService.get('Registration', 'GetAuthToken', '').toPromise();
    if (AuthTokenDetails) {
      let karmaConfig: any = {};
      karmaConfig.userAuthtoken = AuthTokenDetails?.access_token;
      karmaConfig.userMail = 'karmauser@mailinator.com';
      karmaConfig.userCompany = 'BSB';
      karmaConfig.agencyCode = '70001';
      window.localStorage.setItem("karmaConfig", JSON.stringify(karmaConfig));

      // setting localstorage values
      karmaConfig = JSON.parse(localStorage.getItem('karmaConfig'));
      this.dao.userAuthtoken = karmaConfig.userAuthtoken;
      this.dao.userMail = karmaConfig.userMail;
      this.dao.userCompany = karmaConfig.userCompany;
      this.dao.RegistrationModal.email = karmaConfig.userMail;
      this.dao.RegistrationModal.agentCode = karmaConfig.agencyCode;

      return karmaConfig;
    }
  }
  // =============== Save Radius for all units ===============
  public async saveRadiusForAllUnits(type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleTotalMilesUpto50.answer = this.dao.editAllUnits.vehicleTotalMilesUpto50.answer;
        data.vehicleTotalMilesUpto200.answer = this.dao.editAllUnits.vehicleTotalMilesUpto200.answer;
        data.vehicleTravelOver200.answer = this.dao.editAllUnits.vehicleTravelOver200.answer;
        data.zoneList = _.cloneDeep(this.dao.editAllUnits.zoneList);
        data.vehicleToZone.answer = this.dao.editAllUnits.vehicleToZone.answer;
        data.vehicleApplicableZonePercent.answer = this.dao.editAllUnits.vehicleApplicableZonePercent.answer;
        data.vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 = true;
        data.farthestApplicableZonePercent.answer = this.dao.editAllUnits.farthestApplicableZonePercent.answer;
        data.farthestToZone.answer = this.dao.editAllUnits.farthestToZone.answer;
      }
    });
    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleTotalMilesUpto50.answer = this.dao.editAllUnits.vehicleTotalMilesUpto50.answer;
        data.vehicleTotalMilesUpto200.answer = this.dao.editAllUnits.vehicleTotalMilesUpto200.answer;
        data.vehicleTravelOver200.answer = this.dao.editAllUnits.vehicleTravelOver200.answer;
        data.zoneList = _.cloneDeep(this.dao.editAllUnits.zoneList);
        data.vehicleToZone.answer = this.dao.editAllUnits.vehicleToZone.answer;
        data.vehicleApplicableZonePercent.answer = this.dao.editAllUnits.vehicleApplicableZonePercent.answer;
        data.vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 = true;
        data.farthestApplicableZonePercent.answer = this.dao.editAllUnits.farthestApplicableZonePercent.answer;
        data.farthestToZone.answer = this.dao.editAllUnits.farthestToZone.answer;
      }
    });
  }
  // =============== Save Comp Deductible for all units ===============
  public async saveCompDeductibleForAllUnits(type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleCompOTCCoverage.answer = type != 'bulkEdit' ? this.dao.editAllUnits.vehicleCompOTCCoverage.answer : 'Yes';
        data.vehicleComp.answer = this.dao.editAllUnits.vehicleComp.answer;
        data.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 = true;
        if (!this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
          data.vehicleColl.answer = this.dao.editAllUnits.vehicleColl.answer;
        }
        if (this.dao?.vehicleModel?.vehicleDefault?.vinEtched?.enabled) {
          data.vinEtched.answer = this.dao.editAllUnits.vinEtched.answer;
        }
      }
    });

    if (this.dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
      this.dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer = type != 'bulkEdit' ? this.dao.editAllUnits.vehicleCompOTCCoverage.answer : 'Yes';
      this.dao.vehicleModel.vehicleItem.vehicleComp.answer = this.dao.editAllUnits.vehicleComp.answer;
      this.dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 = true;
      if (!this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
        this.dao.vehicleModel.vehicleItem.vehicleColl.answer = this.dao.editAllUnits.vehicleColl.answer;
      }
      if (this.dao?.vehicleModel?.vehicleDefault?.vinEtched?.enabled) {
        this.dao.vehicleModel.vehicleItem.vinEtched.answer = this.dao.editAllUnits.vinEtched.answer;
      }
    }
    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleCompOTCCoverage.answer = type != 'bulkEdit' ? this.dao.editAllUnits.vehicleCompOTCCoverage.answer : 'Yes';
        data.vehicleComp.answer = this.dao.editAllUnits.vehicleComp.answer;
        data.vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 = true;
        if (!this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
          data.vehicleColl.answer = this.dao.editAllUnits.vehicleColl.answer;
        }
        if (this.dao?.vehicleModel?.vehicleDefault?.vinEtched?.enabled) {
          data.vinEtched.answer = this.dao.editAllUnits.vinEtched.answer;
        }
      }
    });
  }
  // =============== Save Coll Deductible for all units ===============
  public async saveCollDeductibleForAllUnits(type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleCollCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleCollCoverage.answer = type != 'bulkEdit' ? this.dao.editAllUnits.vehicleCollCoverage.answer : 'Yes';
        data.vehicleColl.answer = this.dao.editAllUnits.vehicleColl.answer;
        data.vehicleCollCoverage.isVehicleUpdatedbyVEH1 = true;
        if (this.dao?.vehicleModel?.vehicleItem?.Q_WhichCollCoverage?.enabled) {
          data.vehicleCollCoverage.answer = this.dao.editAllUnits.vehicleCollCoverage.answer;
          data.Q_WhichCollCoverage.answer = this.dao.editAllUnits.Q_WhichCollCoverage.answer;
          data.broadenedCollision.answer = this.dao.editAllUnits.broadenedCollision.answer;
          data.Q_limitedCollision.answer = this.dao.editAllUnits.Q_limitedCollision.answer;
          data.limitedCollision.answer = this.dao.editAllUnits.limitedCollision.answer;
        }
      }
    });
    if (this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
      this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.answer = type != 'bulkEdit' ? this.dao.editAllUnits.vehicleCollCoverage.answer : 'Yes';
      this.dao.vehicleModel.vehicleItem.vehicleColl.answer = this.dao.editAllUnits.vehicleColl.answer;
      this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.isVehicleUpdatedbyVEH1 = true;
      if (this.dao?.vehicleModel?.vehicleItem?.Q_WhichCollCoverage?.enabled) {
        this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.answer = this.dao.editAllUnits.vehicleCollCoverage.answer;
        this.dao.vehicleModel.vehicleItem.Q_WhichCollCoverage.answer = this.dao.editAllUnits.Q_WhichCollCoverage.answer;
        this.dao.vehicleModel.vehicleItem.broadenedCollision.answer = this.dao.editAllUnits.broadenedCollision.answer;
        this.dao.vehicleModel.vehicleItem.Q_limitedCollision.answer = this.dao.editAllUnits.Q_limitedCollision.answer;
        this.dao.vehicleModel.vehicleItem.limitedCollision.answer = this.dao.editAllUnits.limitedCollision.answer;
      }
    }
    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleCollCoverage.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleCollCoverage.answer = type != 'bulkEdit' ? this.dao.editAllUnits.vehicleCollCoverage.answer : 'Yes';
        data.vehicleColl.answer = this.dao.editAllUnits.vehicleColl.answer;
        data.vehicleCollCoverage.isVehicleUpdatedbyVEH1 = true;
        if (this.dao?.vehicleModel?.vehicleItem?.Q_WhichCollCoverage?.enabled) {
          data.vehicleCollCoverage.answer = this.dao.editAllUnits.vehicleCollCoverage.answer;
          data.Q_WhichCollCoverage.answer = this.dao.editAllUnits.Q_WhichCollCoverage.answer;
          data.broadenedCollision.answer = this.dao.editAllUnits.broadenedCollision.answer;
          data.Q_limitedCollision.answer = this.dao.editAllUnits.Q_limitedCollision.answer;
          data.limitedCollision.answer = this.dao.editAllUnits.limitedCollision.answer;
        }
      }
    });
  }

  // =============== Save Garaging information for all units ===============
  public async saveGaragingInformationForAllUnits(type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleIsCorrectGaragingAddress.answer = this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer;
        data.vehicleGarageStreetAddress.answer = this.dao.editAllUnits.vehicleGarageStreetAddress.answer;
        data.vehicleGarageState.answer = this.dao.editAllUnits.vehicleGarageState.answer;
        data.vehicleGarageCity.answer = this.dao.editAllUnits.vehicleGarageCity.answer;
        data.vehicleGaragePostalCode.answer = this.dao.editAllUnits.vehicleGaragePostalCode.answer;
        data.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 = true;
      }
    });

    if (this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
      this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer = this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer;
      this.dao.vehicleModel.vehicleItem.vehicleGarageStreetAddress.answer = this.dao.editAllUnits.vehicleGarageStreetAddress.answer;
      this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer = this.dao.editAllUnits.vehicleGarageState.answer;
      this.dao.vehicleModel.vehicleItem.vehicleGarageCity.answer = this.dao.editAllUnits.vehicleGarageCity.answer;
      this.dao.vehicleModel.vehicleItem.vehicleGaragePostalCode.answer = this.dao.editAllUnits.vehicleGaragePostalCode.answer;
      this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 = true;
    }


    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleIsCorrectGaragingAddress.answer = this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer;
        data.vehicleGarageStreetAddress.answer = this.dao.editAllUnits.vehicleGarageStreetAddress.answer;
        data.vehicleGarageState.answer = this.dao.editAllUnits.vehicleGarageState.answer;
        data.vehicleGarageCity.answer = this.dao.editAllUnits.vehicleGarageCity.answer;
        data.vehicleGaragePostalCode.answer = this.dao.editAllUnits.vehicleGaragePostalCode.answer;
        data.vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 = true;
      }
    });
  }

  // =============== Save Custom Parts for all units ===============
  public async saveCustomPartsForAllUnits(type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleCustomPartsParent.answer = this.dao.editAllUnits.vehicleCustomPartsParent.answer;
        data.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 = true;
      }
    });

    if (this.dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
      this.dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer = this.dao.editAllUnits.vehicleCustomPartsParent.answer;
      this.dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 = true;
    }

    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleCustomPartsParent.answer = this.dao.editAllUnits.vehicleCustomPartsParent.answer;
        data.vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 = true;
      }
    });
  }

  // =============== Save Additional Insured / Loss Payee for all units ===============
  public async saveInsuredLossPayeeForAllUnits(type) {
    this.dao.vehicleModel.VehiclesList.forEach(data => {
      if (data.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleAddInsuredOrLossPayee.answer = this.dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer;
        data.interestList = _.cloneDeep(this.dao.editAllUnits.interestList);
        data.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 = true;
      }
    });

    if (this.dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
      this.dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer = this.dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer;
      this.dao.vehicleModel.vehicleItem.interestList = this.dao.editAllUnits.interestList;
      this.dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 = true;
    }

    this.dao.vehicleModel.trailerList.forEach(data => {
      if (data.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 == false || type == 'bulkEdit') {
        data.vehicleAddInsuredOrLossPayee.answer = this.dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer;
        data.interestList = _.cloneDeep(this.dao.editAllUnits.interestList);
        data.vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 = true;
      }
    });
  }


  //====== Data to be copied Checkbox In VEH-1 =============== 
  public async SetRadiusAndCovergeToAllUnits(IsCheckBoxClicked) {
    if (IsCheckBoxClicked == true) {
      // Set default data to all units
      if (this.dao.VehicleBulkEditRadiusCheckbox == true) {
        this.dao.editAllUnits.vehicleTotalMilesUpto50.answer = this.dao.vehicleModel.VehiclesList[0].vehicleTotalMilesUpto50.answer;
        this.dao.editAllUnits.vehicleTotalMilesUpto200.answer = this.dao.vehicleModel.VehiclesList[0].vehicleTotalMilesUpto200.answer;
        this.dao.editAllUnits.vehicleTravelOver200.answer = this.dao.vehicleModel.VehiclesList[0].vehicleTravelOver200.answer;
        this.dao.editAllUnits.vehicleToZone.answer = this.dao.vehicleModel.VehiclesList[0].vehicleToZone.answer;
        this.dao.editAllUnits.vehicleApplicableZonePercent.answer = this.dao.vehicleModel.VehiclesList[0].vehicleApplicableZonePercent.answer;
        this.dao.editAllUnits.farthestApplicableZonePercent.answer = this.dao.vehicleModel.VehiclesList[0].farthestApplicableZonePercent.answer;
        this.dao.editAllUnits.farthestToZone.answer = this.dao.vehicleModel.VehiclesList[0].farthestToZone.answer;
        this.dao.editAllUnits.zoneList = _.cloneDeep(this.dao.vehicleModel.VehiclesList[0].zoneList);

        await this.saveRadiusForAllUnits('vehicle');
      }

      if (this.dao.VehicleBulkEditCompCoverageCheckbox == true) {
        this.dao.editAllUnits.vehicleCompOTCCoverage.answer = this.dao.vehicleModel.VehiclesList[0].vehicleCompOTCCoverage.answer;
        this.dao.editAllUnits.vehicleComp.answer = this.dao.vehicleModel.VehiclesList[0].vehicleComp.answer;
        if (!this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
          this.dao.editAllUnits.vehicleColl.answer = this.dao.vehicleModel.VehiclesList[0].vehicleColl.answer;
        }
        if (this.dao?.vehicleModel?.vehicleItem?.vinEtched?.enabled) {
          this.dao.editAllUnits.vinEtched.answer = this.dao.vehicleModel.VehiclesList[0].vinEtched.answer;
        }
        await this.saveCompDeductibleForAllUnits('vehicle');
      }

      if (this.dao.VehicleBulkEditCollCoverageCheckbox == true) {
        this.dao.editAllUnits.vehicleCollCoverage.answer = this.dao.vehicleModel.VehiclesList[0].vehicleCollCoverage.answer;
        this.dao.editAllUnits.vehicleColl.answer = this.dao.vehicleModel.VehiclesList[0].vehicleColl.answer;
        if (this.dao?.vehicleModel?.vehicleItem?.broadenedCollision?.enabled) {
          this.dao.editAllUnits.Q_limitedCollision.answer = this.dao.vehicleModel.VehiclesList[0].Q_limitedCollision.answer;
          this.dao.editAllUnits.limitedCollision.answer = this.dao.vehicleModel.VehiclesList[0].limitedCollision.answer;
          this.dao.editAllUnits.Q_WhichCollCoverage.answer = this.dao.vehicleModel.VehiclesList[0].Q_WhichCollCoverage.answer;
          this.dao.editAllUnits.broadenedCollision.answer = this.dao.vehicleModel.VehiclesList[0].broadenedCollision.answer;
        }

        await this.saveCollDeductibleForAllUnits('vehicle');
      }

      if (this.dao.VehicleBulkEditGaragingCheckbox == true) {
        this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer = this.dao.vehicleModel.VehiclesList[0].vehicleIsCorrectGaragingAddress.answer;
        this.dao.editAllUnits.vehicleGarageStreetAddress.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGarageStreetAddress.answer;
        this.dao.editAllUnits.vehicleGarageState.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGarageState.answer;
        this.dao.editAllUnits.vehicleGarageCity.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGarageCity.answer;
        this.dao.editAllUnits.vehicleGaragePostalCode.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGaragePostalCode.answer;

        await this.saveGaragingInformationForAllUnits('vehicle');
      }

      if (this.dao.VehicleBulkEditCustomPartsCheckbox == true) {
        this.dao.editAllUnits.vehicleCustomPartsParent.answer = this.dao.vehicleModel.VehiclesList[0].vehicleCustomPartsParent.answer;

        await this.saveCustomPartsForAllUnits('vehicle');
      }
      if (this.dao.VehicleBulkEditInsuredLossPayeeCheckbox == true) {
        this.dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer = this.dao.vehicleModel.VehiclesList[0].vehicleAddInsuredOrLossPayee.answer;
        this.dao.editAllUnits.interestList = _.cloneDeep(this.dao.vehicleModel.VehiclesList[0].interestList);

        await this.saveInsuredLossPayeeForAllUnits('vehicle');
      }
    } else {
      // Set default data to newly added vehicle / trailer
      if (this.dao.VehicleBulkEditRadiusCheckbox == true) {
        if (!this.dao.isTrailer) {
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleTotalMilesUpto50.answer = this.dao.vehicleModel.VehiclesList[0].vehicleTotalMilesUpto50.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleTotalMilesUpto200.answer = this.dao.vehicleModel.VehiclesList[0].vehicleTotalMilesUpto200.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleTravelOver200.answer = this.dao.vehicleModel.VehiclesList[0].vehicleTravelOver200.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].zoneList = _.cloneDeep(this.dao.vehicleModel.VehiclesList[0].zoneList);
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleToZone.answer = this.dao.vehicleModel.VehiclesList[0].vehicleToZone.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleApplicableZonePercent.answer = this.dao.vehicleModel.VehiclesList[0].vehicleApplicableZonePercent.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].farthestApplicableZonePercent.answer = this.dao.vehicleModel.VehiclesList[0].farthestApplicableZonePercent.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].farthestToZone.answer = this.dao.vehicleModel.VehiclesList[0].farthestToZone.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 = true;
        } else {
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleTotalMilesUpto50.answer = this.dao.vehicleModel.VehiclesList[0].vehicleTotalMilesUpto50.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleTotalMilesUpto200.answer = this.dao.vehicleModel.VehiclesList[0].vehicleTotalMilesUpto200.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleTravelOver200.answer = this.dao.vehicleModel.VehiclesList[0].vehicleTravelOver200.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].zoneList = _.cloneDeep(this.dao.vehicleModel.VehiclesList[0].zoneList);
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleToZone.answer = this.dao.vehicleModel.VehiclesList[0].vehicleToZone.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleApplicableZonePercent.answer = this.dao.vehicleModel.VehiclesList[0].vehicleApplicableZonePercent.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].farthestApplicableZonePercent.answer = this.dao.vehicleModel.VehiclesList[0].farthestApplicableZonePercent.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].farthestToZone.answer = this.dao.vehicleModel.VehiclesList[0].farthestToZone.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleTotalMilesUpto50.isVehicleUpdatedbyVEH1 = true;
        }
      }
      if (this.dao.VehicleBulkEditCompCoverageCheckbox == true) {
        if (!this.dao.isTrailer) {
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleCompOTCCoverage.answer = this.dao.vehicleModel.VehiclesList[0].vehicleCompOTCCoverage.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleComp.answer = this.dao.vehicleModel.VehiclesList[0].vehicleComp.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 = true;
          if (!this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
            this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleColl.answer = this.dao.vehicleModel.VehiclesList[0].vehicleColl.answer;
          }
          if (this.dao?.vehicleModel?.vehicleDefault?.vinEtched?.enabled) {
            this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vinEtched.answer = this.dao.vehicleModel.VehiclesList[0].vinEtched.answer;
          }
        } else {
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleCompOTCCoverage.answer = this.dao.vehicleModel.VehiclesList[0].vehicleCompOTCCoverage.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleComp.answer = this.dao.vehicleModel.VehiclesList[0].vehicleComp.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleCompOTCCoverage.isVehicleUpdatedbyVEH1 = true;
          if (!this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
            this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleColl.answer = this.dao.vehicleModel.VehiclesList[0].vehicleColl.answer;
          }
          if (this.dao?.vehicleModel?.vehicleDefault?.vinEtched?.enabled) {
            this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vinEtched.answer = this.dao.vehicleModel.VehiclesList[0].vinEtched.answer;
          }
        }
      }
      if (this.dao.VehicleBulkEditCollCoverageCheckbox == true) {
        if (!this.dao.isTrailer) {
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleCollCoverage.answer = this.dao.vehicleModel.VehiclesList[0].vehicleCollCoverage.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleColl.answer = this.dao.vehicleModel.VehiclesList[0].vehicleColl.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleCollCoverage.isVehicleUpdatedbyVEH1 = true;
          if (this.dao?.vehicleModel?.vehicleItem?.broadenedCollision?.enabled) {
            this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].Q_limitedCollision.answer = this.dao.vehicleModel.VehiclesList[0].Q_limitedCollision.answer;
            this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].limitedCollision.answer = this.dao.vehicleModel.VehiclesList[0].limitedCollision.answer;
            this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].Q_WhichCollCoverage.answer = this.dao.vehicleModel.VehiclesList[0].Q_WhichCollCoverage.answer;
            this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].broadenedCollision.answer = this.dao.vehicleModel.VehiclesList[0].broadenedCollision.answer;
          }
        } else {
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleCollCoverage.answer = this.dao.vehicleModel.VehiclesList[0].vehicleCollCoverage.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleColl.answer = this.dao.vehicleModel.VehiclesList[0].vehicleColl.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleCollCoverage.isVehicleUpdatedbyVEH1 = true;
          if (this.dao?.vehicleModel?.vehicleItem?.broadenedCollision?.enabled) {
            this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].Q_limitedCollision.answer = this.dao.vehicleModel.VehiclesList[0].Q_limitedCollision.answer;
            this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].limitedCollision.answer = this.dao.vehicleModel.VehiclesList[0].limitedCollision.answer;
            this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].Q_WhichCollCoverage.answer = this.dao.vehicleModel.VehiclesList[0].Q_WhichCollCoverage.answer;
            this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].broadenedCollision.answer = this.dao.vehicleModel.VehiclesList[0].broadenedCollision.answer;
          }
          if (this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleCollCoverage.answer == 'Yes') {
            if (this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].floorTrailer.answer == '') {
              this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].floorTrailer.answer = 'No';
            }
          }
        }
      }

      if (this.dao.VehicleBulkEditGaragingCheckbox == true) {
        if (!this.dao.isTrailer) {
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleIsCorrectGaragingAddress.answer = this.dao.vehicleModel.VehiclesList[0].vehicleIsCorrectGaragingAddress.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleGarageStreetAddress.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGarageStreetAddress.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleGarageState.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGarageState.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleGarageCity.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGarageCity.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleGaragePostalCode.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGaragePostalCode.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 = true;
        } else {
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleIsCorrectGaragingAddress.answer = this.dao.vehicleModel.VehiclesList[0].vehicleIsCorrectGaragingAddress.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleGarageStreetAddress.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGarageStreetAddress.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleGarageState.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGarageState.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleGarageCity.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGarageCity.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleGaragePostalCode.answer = this.dao.vehicleModel.VehiclesList[0].vehicleGaragePostalCode.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleIsCorrectGaragingAddress.isVehicleUpdatedbyVEH1 = true;
        }
      }

      if (this.dao.VehicleBulkEditCustomPartsCheckbox == true) {
        if (!this.dao.isTrailer) {
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleCustomPartsParent.answer = this.dao.vehicleModel.VehiclesList[0].vehicleCustomPartsParent.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 = true;
        } else {
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleCustomPartsParent.answer = this.dao.vehicleModel.VehiclesList[0].vehicleCustomPartsParent.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleCustomPartsParent.isVehicleUpdatedbyVEH1 = true;
        }
      }

      if (this.dao.VehicleBulkEditInsuredLossPayeeCheckbox == true) {
        if (!this.dao.isTrailer) {
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleAddInsuredOrLossPayee.answer = this.dao.vehicleModel.VehiclesList[0].vehicleAddInsuredOrLossPayee.answer;
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].interestList = _.cloneDeep(this.dao.vehicleModel.VehiclesList[0].interestList);
          this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 = true;
        } else {
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleAddInsuredOrLossPayee.answer = this.dao.vehicleModel.VehiclesList[0].vehicleAddInsuredOrLossPayee.answer;
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].interestList = _.cloneDeep(this.dao.vehicleModel.VehiclesList[0].interestList);
          this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].vehicleAddInsuredOrLossPayee.isVehicleUpdatedbyVEH1 = true;
        }
      }
    }
  }
  public async GetAgencyWIPData() {
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail] }
    await this.commonService.get('workInProgress', 'GetAgencyWIPData', payload).subscribe((event: any) => {
      if (event) {
        this.dao.AgencySubmissionList = event as workInProgress[];
      }
    });
  }

  public async GetAllUsers() {
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail] }
    await this.commonService.get('Agent', 'GetAllUsers', payload).subscribe(async (event: any) => {
      if (event) {
        this.dao.UserManagement.UsersList = event;
        this.dao.UserManagement.tempUsersList = _.cloneDeep(this.dao.UserManagement.UsersList);
        this.dao.RegistrationModal = new RegistrationModal();
        if (this.dao.UserManagement.UsersList.length > 0) {
          if (this.dao.IsInternalUser != true) {
            const curUserProfile = event.filter(x => x.email == this.dao.userMail);
            if (curUserProfile != null) {
              curUserProfile.forEach(element => {
                // If claims does not exist get agency code directly from response
                if(!!element.claims){
                    const curClaim = element.claims.filter(x => x.applicationName == 'BSBAgentPortal' && x.claimName == 'agencycode' && x.company == 'BSB')
                    if (curClaim != null && curClaim.length > 0) {
                        this.dao.UserManagement.userSearch.agencyCode = curClaim[0].claimValue;
                    }
                }
                else {
                    this.dao.UserManagement.userSearch.agencyCode = element.agencyCode;
                }
                this.dao.RegistrationModal.agentCode = this.dao.UserManagement.userSearch.agencyCode;
              });
            }
          }
        } else {
          let userclaimdetails: any = await this.CheckUserClaims(this.dao.userMail);
          this.dao.UserManagement.userSearch.agencyCode = userclaimdetails?.agencyCode;
          this.dao.RegistrationModal.agentCode = this.dao.UserManagement.userSearch.agencyCode;
        }

      }
    });
  }
  public async DeleteUserByClaimId(userCLaimID) {
    let payload = { "urlData": [userCLaimID] }
    return await this.DeleteUserByClaimIdData(payload).then(async (data: any) => {
      if (data) {
        return data;
      }
    });

  }
  public async DeleteUserByClaimIdData(payload) {
    return await this.commonService.get('Agent', 'DeleteUser', payload).toPromise();
  }
  public async postAgencyRights(isCheckboxChecked) {
    if (this.dao.IsInternalUser) {
      if (isCheckboxChecked == true) {
        if (this.dao.UserManagement.userSearch.inactiveUser == false) {
          this.dao.BSBAgencyList.forEach(element => {
            element.isEndorsementAccessed = false;
          });
          this.dao.EndorsementList = this.dao.BSBAgencyList;
          this.dao.EndorsementTargetList = [];

          let payload = { "urlData": [this.dao.userCompany, this.dao.userMail], "payloadData": this.dao.BSBAgencyList }
          return await this.postAgencyRightsData(payload).then(async (data: any) => {
            if (data) {
              return data;
            }
          });
        }
      } else {
        if (this.dao.UserManagement.userSearch.inactiveUser == false) {
          this.dao.BSBAgencyList.forEach(element => {
            element.isEndorsementAccessed = false;
          });
        } else {
          if (this.dao.EndorsementTargetList != null) {
            this.dao.EndorsementTargetList.forEach(element => {
              this.dao.BSBAgencyList.filter(x => x.code == element.code)[0].isEndorsementAccessed = true;
            });
          }
          if (this.dao.EndorsementList != null) {
            this.dao.EndorsementList.forEach(element => {
              this.dao.BSBAgencyList.filter(x => x.code == element.code)[0].isEndorsementAccessed = false;
            });
          }
        }

        let payload = { "urlData": [this.dao.userCompany, this.dao.userMail], "payloadData": this.dao.BSBAgencyList }

        return await this.postAgencyRightsData(payload).then((data: any) => {
          if (data) {
            return data;
          }
        });
      }
    }
  }
  public async postAgencyRightsData(payload) {
    return await this.commonService.post('Agent', 'postAgencyRights', payload).toPromise();
  }

  public async throwVehicleValidationPopup() {
    this.dao.NotAllowedToSubmit = false;
    this.dao.IsSavedFromMenu = false;
    this.dao.isEmptyListFromMenu = true;

    document.body.style.pointerEvents = "none";
    if (this.dao.PolicyTemplate == 'vehicles') {
      this.messageService.add({ key: 'madeChange', sticky: true, severity: 'warn', summary: "If you leave this page without saving, your data will be lost. Are you sure you want to leave this page?" });
    }
  }
  // ===== Change loss question based UW cargo question

  public async BindcargoForProduct(type) {
    let ProductModelObj = '$..questionnaire[?(@.key =="product")]'
    let getproductdata = '$..questionnaire[?(@.key =="product")].questionAnswer';
    let getproductdataList = _.max(this.getObjUsingJpath(this.dao.DOTList, getproductdata));
    if (type == 'CargoRemoved') {
      this.dao.AddAnotherInsuredForCargo = [];
      this.dao.ProductModel.productDetails.cargoNameInsuredParent.answer = '';

      let flatDeductible = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="flatDeductible")].questionDefault[0].code'
      this.dao.ProductModel.productDetails.flatDeductible.answer = this.getObjUsingJpath(this.dao.DOTList, flatDeductible)[0];
    }
    else {
      if (this.dao.AddAnotherInsuredForCargo.length == 0) {
        let getMaxRow = '$..questionnaire[?(@.key =="product")].questionAnswer[?(@.key =="cargoInsuredName")].rowIndex';
        let getMaxRowIndex = _.max(this.getObjUsingJpath(this.dao.DOTList, getMaxRow));
        for (var i = 1; i <= getMaxRowIndex; i++) {
          this.dao.AddAnotherInsuredForCargo.push({
            'Name': getproductdataList?.filter(f => f.key == 'cargoInsuredName' && f.rowIndex == i)[0]?.answer,
            'Street': getproductdataList?.filter(f => f.key == 'cargoInsuredStreet' && f.rowIndex == i)[0]?.answer,
            'City': getproductdataList?.filter(f => f.key == 'cargoInsuredCity' && f.rowIndex == i)[0]?.answer,
            'State': getproductdataList?.filter(f => f.key == 'cargoInsuredState' && f.rowIndex == i)[0]?.answer,
            'PostalCode': getproductdataList?.filter(f => f.key == 'cargoInsuredPostalCode' && f.rowIndex == i)[0]?.answer
          });
        }
        this.dao.InitialAddAnotherInsuredForCargo = _.cloneDeep(this.dao.AddAnotherInsuredForCargo)
      }
    }
  }
  public setActiveMenu(page = '') {
    let response: boolean = false;
    if (this.dao.MenuList != null) {
      let id = this.dao.MenuList.filter(x => x.text == (page == '' ? this.dao.PolicyTemplate : page))[0]?.id;
      let finalSubmitPageId = this.dao.MenuList.filter(x => x.text == this.dao.navigatePage)[0]?.id;
      this.dao.MenuList.forEach(x => {
        if (x.id == id) {
          x.isActive = true;
          x.cssClass = 'active current_menu_Size'
          x.arrowCss = 'arrow_active'
        } else if (x.id <= id + 1 && x.id <= finalSubmitPageId) {
          x.isActive = true;
          x.cssClass = 'active';
          x.arrowCss = 'arrow_active'
          response = true;
        } else if (x.id != id + 1 && x.id <= finalSubmitPageId) {
          x.isActive = false;
          x.cssClass = 'active active_grey';
          x.arrowCss = 'active active_grey';
        } else {
          x.isActive = false;
          x.cssClass = '';
        }

        if (x.id == id + 1) {
          x.arrowCss = 'arrow_active arrow_next_to_current_tab'
        }
      });
      if (id == 1) {
        response = true;
      }
    }
    return response;
  }

  public async downloadSampleTemplate(id, fileName) {
    let payload = { "urlData": [id] }
    let response: any = {};
    await this.commonService.get('SampleExcelTemplate', 'GetSampleExcelTemplate', payload).subscribe((event: any) => {
      if (event != null) {
        response = event;
        var arrrayBuffer = this.base64ToArrayBuffer(response.pdfStream);
        var blob = new Blob([arrrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    });
  }
  public async postResponseContainer(componentName: string, functionality: string, payload?: any): Promise<ResponseContainer> {
    var dataObject: any = await this.commonService.post(componentName, functionality, payload).toPromise();
    return Promise.resolve(ResponseContainer.fromJSON(dataObject));
  }
  public async getResponseContainer(componentName: string, functionality: string, payload?: any): Promise<ResponseContainer> {
    var dataObject: any = await this.commonService.get(componentName, functionality, payload).toPromise();
    return Promise.resolve(ResponseContainer.fromJSON(dataObject));
  }

  public isPolicyEffectiveOnJulyAndCorrectState(policyEffectiveDate, accountState): boolean {
    if (isNaN(new Date(policyEffectiveDate)?.getTime())) {
      return false;
    }

    const effectiveDate = this.dateService.getCorrectDate(policyEffectiveDate);
    let clearancePhase1 = ["AZ", "IL", "MN"].includes(accountState) && effectiveDate.getTime() >= new Date("07/01/2024").getTime();
    let clearancePhase2 = !["AZ", "IL", "MN"].includes(accountState) && effectiveDate.getTime() >= new Date("08/01/2024").getTime();
    return clearancePhase1 || clearancePhase2;
  }

  public getPolicyHolderVersion(policyEffectiveDate, accountState): boolean {
    const hasVehicleTravel = !!this.dao.DOTList.accountInfoQsnr.findQuestion('vehicleTravel');
    if (!hasVehicleTravel) {
      return false;
    }

    if (this.isPolicyEffectiveOnJulyAndCorrectState(policyEffectiveDate, accountState)) {
      return true;
    }

    return false;
  }

  /**
   * Determines if policy holder meets the New Trucking Model Logic based on policy effective date and valuation date.
   */
  public getPolicyHolderVersionNTM(policyEffectiveDate, accountState): boolean {
    if (!this.getPolicyHolderVersion(policyEffectiveDate, accountState)) {
      return false;
    }
    if (!!!this.dao.DOTList.accountInfoQsnr.findQuestion('doesFileIfta')) {
      return false;
    }
    const ntmEnableSettings = this.dao.DOTList.accountInfoQsnr.findQuestion('ntm_Enable_Settings');
    if (!!!ntmEnableSettings) {
      return false;
    }

    const effectiveDate = this.dateService.getCorrectDate(policyEffectiveDate);
    return ntmEnableSettings.questionOptions.some((option) => option.code === accountState && effectiveDate.getTime() >= new Date(option.label).getTime());
  }

  /**
   * Determines which loss version to use based on state, policy effective date, and valuation date.
   */
  get lossHistoryVersion(): number {
    const lossQuestionnaire: Questionnaire = this.dao.DOTList?.losssHistoryQuestionnaire;
    var valuationDateQuestionAnswers = lossQuestionnaire?.findQuestions("valuationDate");
    if (!!!valuationDateQuestionAnswers || valuationDateQuestionAnswers.length === 0) {
      return 1;
    }

    const policyEffectiveDate = this.dao.DOTList.accountInfoQsnr.findQuestion('policyEffectiveDate')?.answer;
    const accountState = this.dao.DOTList.accountInfoQsnr.findQuestion('accountState')?.answer;
    if (this.isPolicyEffectiveOnJulyAndCorrectState(policyEffectiveDate, accountState)) {
      return 2;
    }

    return 1;
  }
}
